import { Gallery, Profile } from "@simplebooth/webkit";
import { ReactNode } from "react";
import { Helmet } from "react-helmet";
import BrandedBackground from "../BrandedBackground/BrandedBackground";
import BrandedHeader from "../BrandedHeader/BrandedHeader";
import Container, { ContainerSize } from "../Container/Container";
import FinePrintDisplayer from "../FinePrintDisplayer/FinePrintDisplayer";
import ProfileLinks from "../GalleryPage/ProfileLinks/ProfileLinks";
import stylesheet from "./GalleryBrandedPage.module.scss"

interface GalleryBrandedPageProps {
  gallery: Gallery
  profile?: Profile
  containerClassName?: string
  containerSize: ContainerSize
  children: ReactNode
  paddingForControls?: boolean
}

export default function GalleryBrandedPage({ gallery, profile, containerClassName, containerSize, children, paddingForControls }: GalleryBrandedPageProps) {
  const showDefaultLogo = gallery.design.showSimpleBoothLogo && gallery.hasDefaultBackgroundColor()

  return (
    <>
      <Helmet>
        <title>{gallery.title}</title>
      </Helmet>
      <BrandedBackground
        fixed
        firstColor={gallery.design.backgroundColor}
        secondColor={gallery.design.backgroundColor}
        allowDefaultBackgroundConfetti={!gallery.hasDesignCustomizations()}
      >
        <Container
          className={`${containerClassName ?? ""} ${stylesheet.bottomPadding}`}
          size={containerSize}
          style={{paddingTop: paddingForControls ? "36px" : 0}}
        >
          {profile && <ProfileLinks profile={profile} /> }
          <BrandedHeader
            showDefaultLogo={showDefaultLogo}
            imageAsset={gallery.design.topImageAsset}
            imageHref={gallery.design.topUrl}
            heading={gallery.title}
            textColor={gallery.design.textColor}
          />
          {children}
        </Container>
        <FinePrintDisplayer gallery={gallery} />
      </BrandedBackground>
    </>
  )
}
