import ActionDots from "../../../../image/action-dots.svg"
// import ReportIcon from "../../../../image/action-report.svg"
import TrashIcon from "../../../../image/action-delete.svg"
import SetPrivateIcon from "../../../../image/action-set-private.svg"
import ViewAdminIcon from "../../../../image/action-view-admin.svg"
import stylesheet from "./UploadControls.module.scss"
import { useEffect, useRef, useState } from "react"
import { API, deleteUpload, makePrivate, Upload } from "@simplebooth/webkit"
import Toast, { ToastType } from "../../../Toast/Toast"
import { SUPPORT_EMAIL } from "../../../../library/globals"
import { useTranslation } from "react-i18next"

interface UploadControlsProps {
  upload: Upload
  showButton: boolean
  editable: boolean
  admin: boolean
  handleReport: (upload: Upload) => void
  onDelete: (upload: Upload) => void
  onDeletePending?: (upload: Upload) => void
  onDeleteCanceled?: (upload: Upload) => void
  onMakePrivate: (upload: Upload) => void
}

enum DropdownAction {
  DELETE, MAKE_PRIVATE
}

export default function UploadControls({ upload, showButton, editable, admin, handleReport, onDelete, onDeletePending, onDeleteCanceled, onMakePrivate }: UploadControlsProps) {
  const [showDropdown, setShowDropdown] = useState(false)
  const [errorCode, setErrorCode] = useState<number | null>(null)
  const [showError, setShowError] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [currentAction, setCurrentAction] = useState<DropdownAction | null>(null)

  const actionGroupRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  useEffect(() => {
    window.addEventListener("click", handleClick)
    return () => window.removeEventListener("click", handleClick)
  })

  const handleClick = (event: MouseEvent) => {
    if (actionGroupRef && actionGroupRef.current && !event.composedPath().includes(actionGroupRef.current)) {
      setShowDropdown(false)
      setConfirmDelete(false)
    }
  }

  const handleDelete = () => {
    setCurrentAction(DropdownAction.DELETE)
    if (onDeletePending) onDeletePending(upload)
    deleteUpload(upload,
      () => onDelete(upload),
      (errorCode) => {
        if (onDeleteCanceled) onDeleteCanceled(upload)
        setErrorCode(errorCode)
      })
  }

  const handleMakePrivate = () => {
    setCurrentAction(DropdownAction.MAKE_PRIVATE)
    makePrivate(upload.code, () => onMakePrivate(upload), setErrorCode)
  }

  const openAdminView = () => {
    window.open(`${API.baseUrl}/admin/uploads/${upload.code}`)
  }

  useEffect(() => {
    if (errorCode !== null) setShowError(true)
  }, [errorCode])

  return (
    <div className={stylesheet.actionGroup} ref={actionGroupRef}>
      <button
        className={`${stylesheet.toggleButton} ${(showButton || showDropdown) ? stylesheet.opaque : stylesheet.transparent}`}
        onClick={(_) => setShowDropdown(!showDropdown)}
        style={{ backgroundImage: `url(${ActionDots})` }}>
      </button>

      <div className={`${stylesheet.dropdown} ${showDropdown ? stylesheet.dropdownIn : stylesheet.dropdownOut}`}>
        {/* TODO: Make reporting photos a real thing */}
        {/* <button
          className={stylesheet.dropdownItem}
          style={{ backgroundImage: `url(${ReportIcon})` }}
          onClick={() => handleReport(upload)}
        >
          Report
        </button> */}
        {admin &&
          <button
            className={`${stylesheet.dropdownItem}`}
            style={{ backgroundImage: `url(${ViewAdminIcon})`, backgroundPositionY: "55%" }}
            onClick={openAdminView}
          >
            Admin View
          </button>
        }
        {editable &&
          <>
            {upload.public &&
              <button
                className={stylesheet.dropdownItem}
                style={{ backgroundImage: `url(${SetPrivateIcon})` }}
                onClick={handleMakePrivate}
              >
                {t("buttons.setPrivate")}
              </button>
            }
            <button
              className={`${stylesheet.dropdownItem} ${stylesheet.destructive}`}
              style={{ backgroundImage: `url(${TrashIcon})` }}
              onClick={() => confirmDelete ? handleDelete() : setConfirmDelete(true)}
            >
              {confirmDelete ? t("buttons.confirmDelete") : t("buttons.delete")}
            </button>
          </>
        }
      </div>

      {/** TODO: It would be better if this was presented at a higher level, through some global notification */}
      {errorCode &&
        <Toast type={ToastType.ERROR} show={showError} onClose={() => setShowError(false)}>
          {currentAction === DropdownAction.DELETE ?
            t('errorState.deleteFailed', { errorCode: errorCode, supportEmail: SUPPORT_EMAIL })
            :
            t('errorState.setPrivateFailed', { errorCode: errorCode, supportEmail: SUPPORT_EMAIL })
          }
        </Toast>
      }
    </div>
  )
}