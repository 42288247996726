/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useMemo, useState } from "react"
import { Gallery, IconWithConfetti, Profile, Spinner } from "@simplebooth/webkit"
import GalleryCard from "./GalleryCard/GalleryCard"
import EmptyState from "../../EmptyState/EmptyState"
import { ReactComponent as EmptyGallery } from "../../../image/empty-gallery.svg"
import FeedFooter from "../../Feed/FeedFooter/FeedFooter"
import stylesheet from "./ProfileGallerySection.module.scss"
import { useTranslation } from "react-i18next"
import throttle from 'lodash.throttle'

interface ProfileGallerySectionProps {
  profile: Profile
}

export default function ProfileGallerySection({ profile }: ProfileGallerySectionProps) {
  const [galleries, setGalleries] = useState<Gallery[] | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [nextLink, setNextLink] = useState<string | null>(null)
  const [errorCode, setErrorCode] = useState<number | null>(null)
  const [cardReadyCount, setCardReadyCount] = useState<number>(0)

  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    profile.fetchPublicGalleries(
      nextLink,
      (galleries, nextLink) => {
        setIsLoading(false)
        setGalleries(processGalleries(galleries))
        setNextLink(nextLink)
      },
      handleError
    )
  }, [profile])

  const processGalleries = (galleries: Gallery[]) => {
    return (
      galleries
      .filter(gallery => gallery.totalUploads >= 15)
      .sort((a, b) => {
        return (b.latestUploadCreatedAt?.getTime() ?? 0) - (a.latestUploadCreatedAt?.getTime() ?? 0)
      })
    )
  }

  const pixelsFromBottomToLoadMoreGalleries = 500
  const handleScroll = () => {
    if (window.scrollY + window.innerHeight >= document.body.offsetHeight - pixelsFromBottomToLoadMoreGalleries) {
      // Load more
      if (isLoading || !nextLink) return

      setIsLoading(true)
      profile.fetchPublicGalleries(
        nextLink,
        (newGalleries, nextLink) => {
          setIsLoading(false)
          setGalleries(
            [...galleries ?? [], ...processGalleries(newGalleries)]
          )
          setNextLink(nextLink)
        },
        handleError
      )
    }
  }

  const throttledHandleScroll = useMemo(
    () => throttle(handleScroll, 300), [isLoading, nextLink, galleries]
  )

  useEffect(() => {
    window.addEventListener("scroll", throttledHandleScroll)
    return () => window.removeEventListener("scroll", throttledHandleScroll)
  })

  const handleError = (error: number) => {
    setErrorCode(error)
    setNextLink(null)
    setIsLoading(false)
  }

  if (galleries && galleries.length === 0) {
    return (
      <EmptyState asCard naked style={{ marginTop: "4vh" }}>
        <IconWithConfetti largeIcon largeShadow style={{ height: "min(360px, 65vw)", width: "100%" }}>
          <EmptyGallery />
        </IconWithConfetti>
        <h1>{t("profile.emptyState.heading")}</h1>
        <p>{t("profile.emptyState.text")}</p>
      </EmptyState>
    )
  }

  return (
    <div className={stylesheet.container}>
      {[...galleries ?? []]
        .splice(0, cardReadyCount + 2) // Load up to 2 at a time
        .map(gallery => {
          return (
            <GalleryCard
              gallery={gallery}
              key={gallery.code}
              didLoad={() => setCardReadyCount(cardReadyCount + 1)}
            />
          )
        })}

      {isLoading &&
        <Spinner style={{ marginTop: "40px" }} />
      }

      {errorCode &&
        <p className={stylesheet.infiniteScrollError}>
          {t('errorState.anyCode.heading', { errorCode: errorCode })}
        </p>
      }

      {(galleries && cardReadyCount >= galleries.length && !nextLink) &&
        <FeedFooter textColor="#000000" />
      }
    </div>
  )
}
