export const copyText = (text: string, handleSuccess: () => void) => {

  function secondaryCopyMethod() {
    var input = document.createElement('input')
    input.setAttribute('value', text)
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)

    handleSuccess()
  }

  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(handleSuccess, secondaryCopyMethod)
  } else {
    secondaryCopyMethod()
  }
}