import EmptyState from '../EmptyState/EmptyState'
import { IconWithConfetti } from "@simplebooth/webkit"
import { ReactComponent as PrivacyLock } from "../../image/privacy-lock.svg"
import { useTranslation, Trans } from 'react-i18next'

export default function GalleryPrivateState() {
  const { t } = useTranslation()

  return (
    <EmptyState fullscreen>
      <IconWithConfetti largeShadow style={{ height: "340px", width: "100%" }}>
        <PrivacyLock />
      </IconWithConfetti>
      <h1>{t("gallery.private.heading")}</h1>
      <p>
        <Trans i18nKey="gallery.private.text">
          The gallery owner has restricted who can view these photos. <a href="https://help.simplebooth.com/en/articles/822500-what-privacy-options-do-i-have-for-my-galleries" target="_blank" rel="noreferrer">Learn more</a>
        </Trans>
      </p>
    </EmptyState>
  )
}
