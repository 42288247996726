/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useMemo } from 'react'
import { Upload, Gallery, fetchGallery, fetchMoreGalleryUploads, Spinner, GallerySortType, Profile } from "@simplebooth/webkit"
import CardController from "./CardController/CardController"
import { FeedItem } from '../../library/FeedItem'
import InView from 'react-intersection-observer'
import ScrollUpButton from './ScrollUpButton/ScrollUpButton'
import { insertAds, resolveCustomAd, removeAds, removeUpload, resolveAdFrequency } from "./FeedUtil"
import stylesheet from "./Feed.module.scss"
import FeedControls from './FeedControls/FeedControls'
import FeedFooter from './FeedFooter/FeedFooter'
import { useTranslation } from 'react-i18next'
import { resolveGalleryDateString } from '../../library/DateTimeUtil'
import throttle from 'lodash.throttle'
import Ad from '../../library/Ad'

interface FeedProps {
  gallery: Gallery
  profile?: Profile
  numberOfColumns: number | null
  defaultSorting?: GallerySortType
  defaultSortingDate?: Date | null
  onGalleryPage?: boolean
  hideControls?: boolean
  excludeUploadCode?: string
  setNumberOfColumns: (numberOfColumns: number) => void
}

export default function Feed({ gallery, profile, numberOfColumns, hideControls, defaultSorting, defaultSortingDate, onGalleryPage, excludeUploadCode, setNumberOfColumns }: FeedProps) {
  const [isTopVisible, setIsTopVisible] = useState(false)
  const isTopVisibleRef = useRef(isTopVisible)
  isTopVisibleRef.current = isTopVisible

  const [sorting, setSorting] = useState(defaultSorting ?? GallerySortType.NEWEST)
  const sortingRef = useRef(sorting)
  sortingRef.current = sorting

  const [items, setItems] = useState<FeedItem[]>([])
  const itemsRef = useRef(items)
  itemsRef.current = items

  const numberOfColumnsRef = useRef(numberOfColumns)
  numberOfColumnsRef.current = numberOfColumns

  const prevNumberOfColumnsRef = useRef<number | null>(null)

  const [date, setDate] = useState<Date | null>(defaultSortingDate ?? null)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [nextUploadsLink, setNextUploadLink] = useState<string | null>(null)
  const [showScrollUpButton, setShowScrollUpButton] = useState(false)
  const [errorCode, setErrorCode] = useState<number | null>(null)
  const [deletionEventSource, setDeletionEventSource] = useState<EventSource | null>(null)

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 600) {
        setNumberOfColumns(1)
      } else if (window.innerWidth < 740) {
        setNumberOfColumns(2)
      } else if (window.innerWidth < 1000) {
        setNumberOfColumns(3)
      } else {
        setNumberOfColumns(4)
      }
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  // Re-position the ads when column count changes
  useEffect(() => {
    if (
      numberOfColumns !== null
      && prevNumberOfColumnsRef.current !== null
      && numberOfColumns !== prevNumberOfColumnsRef.current
    ) {
      resetAds()
    }

    prevNumberOfColumnsRef.current = numberOfColumns
  }, [numberOfColumns])

  // Re-position the ads when the profile becomes available for profile ads
  useEffect(() => {
    if (!gallery.ads.profileAdDisplayingEnabled) return
    resetAds()
  }, [profile])

  const { t } = useTranslation()

  const newestUploadsToFetch = 16
  const popularUploadsToFetch = 32

  const pollNewPhotosInterval = 10 // seconds
  const pixelsFromBottomToLoadMorePhotos = 1200 // pixels

  const [firstLoad, setFirstLoad] = useState(true)

  // Start the initial gallery fetch, or restart when sorting changes
  useEffect(() => {
    if (gallery.uploads.length >= newestUploadsToFetch
      && firstLoad
      && sorting === GallerySortType.NEWEST
    ) {
      handleGallery(gallery, true)
    } else {
      setItems([])
      setNextUploadLink(null)
      setIsLoading(true)
      fetchGallery(
        gallery.code,
        sorting,
        sorting === GallerySortType.NEWEST ? newestUploadsToFetch : popularUploadsToFetch,
        date ? { to: date, from: date } : null,
        resource => handleGallery(Gallery.fromLegacyResource(resource), true),
        handleError
      )
    }
    setFirstLoad(false)
  }, [sorting, date])

  const handleScroll = () => {
    if (window.scrollY + window.innerHeight >= document.body.offsetHeight - pixelsFromBottomToLoadMorePhotos) {
      if (isLoading || !nextUploadsLink) return

      // Load more photos
      setIsLoading(true)
      fetchMoreGalleryUploads(
        nextUploadsLink,
        (resource) => {
          handleGallery(Gallery.fromLegacyResource(resource))
        },
        handleError
      )
    }
    setShowScrollUpButton(window.scrollY > 5000)
  }

  const throttledHandleScroll = useMemo(
    () => throttle(handleScroll, 300), [isLoading, nextUploadsLink]
  )

  useEffect(() => {
    window.addEventListener("scroll", throttledHandleScroll)
    return () => window.removeEventListener("scroll", throttledHandleScroll)
  })

  useEffect(() => {
    // Start polling for new photos
    const interval = setInterval(() => {
      // Only look for new photos when at the top of the feed
      if (!isTopVisibleRef.current) return

      // Only look for new photos when sorted by newest
      if (sortingRef.current !== GallerySortType.NEWEST) return

      // Re-fetch the latest uploads in the gallery
      fetchGallery(
        gallery.code,
        GallerySortType.NEWEST,
        newestUploadsToFetch,
        date ? { to: date, from: date } : null,
        (resource) => {
          if (resource.uploads.length === 0) return

          // Update our items if the first upload is different from our current first upload
          if (items.length < resource.uploads.length
            || (items.length > 0 && items[0] instanceof Upload && items[0].code !== resource.uploads[0].id)
          ) {
            handleGallery(Gallery.fromLegacyResource(resource), true)
          }
        },
        handleError
      )
    }, pollNewPhotosInterval * 1000);
    return () => clearInterval(interval);
  }, [])

  const resolveAds = () => {
    const ads = []
    if (gallery.design.bottomImageAsset != null) {
      ads.push(resolveCustomAd(gallery))
    }
    if (gallery.ads.profileAdDisplayingEnabled && profile) {
      ads.push(Ad.fromProfile(profile))
    }
    return ads
  }

  function resetAds() {
    if (numberOfColumns === null) return

    let newItems = removeAds([...items])

    insertAds(
      resolveAds(),
      newItems,
      resolveAdFrequency(numberOfColumns)
    )
    setItems(newItems)
  }

  const handleGallery = (gallery: Gallery, initialLoad = false) => {
    let currentItems = initialLoad ? [] : itemsRef.current

    let newUploads = gallery.uploads
    if (excludeUploadCode) {
      newUploads = newUploads.filter((upload) => upload.code !== excludeUploadCode)
    }

    let newItems = [...currentItems, ...newUploads]

    insertAds(
      resolveAds(),
      newItems,
      resolveAdFrequency(numberOfColumnsRef.current ?? 1)
    )

    setItems(newItems)
    if (sorting !== GallerySortType.POPULAR) {
      setNextUploadLink(gallery.links.nextUploads ?? null)
    }
    setIsLoading(false)
  }

  const handleError = (error: number) => {
    setErrorCode(error)
    setIsLoading(false)
  }

  // Listen for upload deletions
  useEffect(() => {
    const sseUrl = (window as any).social?.config?.sseUrl ?? process.env.REACT_APP_SSE_URL as string
    let eventSource = new EventSource(sseUrl)

    eventSource.addEventListener("upload.delete", (event: any) => {
      let deletedUploadCode = JSON.parse(event.data).code
      if (itemsRef.current == null) return

      performDelete(deletedUploadCode)
    })

    setDeletionEventSource(eventSource)
    return () => { deletionEventSource?.close() }
  }, [])

  const performDelete = (code: string) => {
    let newList = removeUpload(code, itemsRef.current)
    if (newList.length < itemsRef.current.length) {
      setItems(newList)
    }
  }

  const reachedEnd = nextUploadsLink === null
  const startOfFeedRef = useRef<HTMLDivElement | null>(null)

  let basePath = `/gallery/${gallery.code}`
  if (gallery.friendlyUrl) basePath = basePath + "-" + gallery.friendlyUrl

  return (
    <div className={stylesheet.feedContainer}>
      {!hideControls &&
        <FeedControls
          gallery={gallery}
          sorting={sorting}
          date={date}
          sortByNewest={() => {
            setSorting(GallerySortType.NEWEST)
            setDate(null)
            if (onGalleryPage) window.history.replaceState(null, document.title, basePath)
          }}
          sortByDate={(date) => {
            setDate(date)
            setSorting(GallerySortType.DATES)
            let dateString = resolveGalleryDateString(date)
            if (onGalleryPage) window.history.replaceState(null, document.title, `${basePath}/dates/${dateString}`)
          }}
          sortByTrending={() => {
            setSorting(GallerySortType.POPULAR)
            setDate(null)
            if (onGalleryPage) window.history.replaceState(null, document.title, `${basePath}/popular`)
          }}
        />
      }

      <InView threshold={0} onChange={(inView) => setIsTopVisible(inView)}>
        <div ref={startOfFeedRef}></div>
      </InView>

      {startOfFeedRef.current &&
        <>
          {onGalleryPage ?
            <ScrollUpButton show={showScrollUpButton} scrollToElement={startOfFeedRef.current} scrollOffset={-80}>
              {sorting === GallerySortType.NEWEST ? t("feed.newestButton") : t("feed.topButton")}
            </ScrollUpButton>
            :
            <ScrollUpButton show={showScrollUpButton}>
              {t("feed.topButton")}
            </ScrollUpButton>
          }
        </>
      }

      <CardController
        gallery={gallery}
        items={items}
        numberOfColumns={numberOfColumns ?? 1}
        handleRemoveUpload={(upload) => performDelete(upload.code)}
        sorting={sorting}
      />

      {isLoading &&
        <Spinner color={gallery.getBackgroundContrastColor()} style={{ display: "block", margin: "40px auto 0 auto" }} />
      }

      {!isLoading && reachedEnd &&
        <FeedFooter textColor={gallery.design.textColor} />
      }

      {errorCode && t('errorState.anyCode.heading', { errorCode: errorCode })}
    </div>
  )
}
