import { Route, Routes, useLocation } from "react-router-dom"
import ReactGA from 'react-ga'
import { useEffect } from "react"
import PicPage from "./components/PicPage/PicPage"
import GalleryPage from "./components/GalleryPage/GalleryPage"
import QrLoadingPage from "./components/QrLoadingPage/QrLoadingPage"
import ProfilePage from "./components/ProfilePage/ProfilePage"
import GalleryEmbed from "./components/GalleryPage/GalleryEmbed/GalleryEmbed"
import PageNotFound from "./components/EmptyState/PageNotFound/PageNotFound"

export default function AppRoutes() {
  ReactGA.initialize(process.env.REACT_APP_GA_ID as string)

  const location = useLocation()

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [location])

  return (
    <Routes>
      <Route path="/pic/:picCode" element={<PicPage />} />
      <Route path="/pic/qr/:qrCode" element={<QrLoadingPage />} />
      <Route path="/gallery/:friendlyUrl" element={<GalleryPage />} />
      <Route path="/gallery/:friendlyUrl/embed" element={<GalleryEmbed />} />
      <Route path="/gallery/:friendlyUrl/:sorting" element={<GalleryPage />} />
      <Route path="/gallery/:friendlyUrl/:sorting/:date" element={<GalleryPage />} />
      <Route path="/profiles/:username" element={<ProfilePage />} />
      <Route element={<PageNotFound />} />
    </Routes>
  )
}