import { ReactNode } from "react"
import { CSSProperties } from "react"
import buttonStyles from "./Button.module.scss"

interface BlueButtonProps {
  style?: CSSProperties
  className?: string
  children?: ReactNode
  compact?: boolean
  onClick?: () => void
}

export default function BlueButton({ children, style, className, compact, onClick }: BlueButtonProps) {
  return (
    <button
      className={`${compact ? buttonStyles.blueCompact : buttonStyles.blue} ${className ?? ""}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
