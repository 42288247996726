import { useState } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import stylesheet from "./ProfileControls.module.scss"
import textarea from "../../../styles/TextArea.module.scss"
import editIconSrc from "../../../image/edit.svg"
import megaphoneIconSrc from "../../../image/megaphone.svg"
import clipboardIconSrc from "../../../image/clipboard.svg"
import checkmarkIconSrc from "../../../image/checkmark.svg"
import loginSrc from "../../../image/login.svg"
import pillStyle from "../../Button/PillButton.module.scss"
import { API, Profile } from "@simplebooth/webkit"
import { Modal } from "react-bootstrap"
import ProfileBadge from "../ProfileBadge/ProfileBadge"
import { copyText } from "../../../library/CopyUtil"
import { useTranslation } from "react-i18next"

interface ProfileControlsProps {
  profile: Profile
  loggedIn: boolean
  canEdit: boolean
}

export default function ProfileControls({ profile, loggedIn, canEdit }: ProfileControlsProps) {
  const [showPromoteModal, setShowPromoteModal] = useState(false)
  const [copied, setCopied] = useState(false)

  const { t } = useTranslation()

  let embedCode = renderToStaticMarkup(<ProfileBadge profile={profile} />).replace(/\n/g, " ").replace(/\s+/g, ' ')

  return (
    <>
      <div className={stylesheet.container}>
        {canEdit &&
          <>
            <button
              className={`${pillStyle.pillButton} ${pillStyle.mobileCollapse}`}
              onClick={() => setShowPromoteModal(true)}
            >
              <img src={megaphoneIconSrc} alt={t("altText.icon")} />
              <span>{t("buttons.promote")}</span>
            </button>
            <a
              href={API.baseUrl + "/profile"}
              className={`${pillStyle.pillButton} ${pillStyle.mobileCollapse}`}
              target="_blank"
              rel="noreferrer"
            >
              <img src={editIconSrc} alt={t("altText.icon")} />
              <span>{t("buttons.edit")}</span>
            </a>
          </>
        }

        {!loggedIn &&
          <a
            href={API.baseUrl + "/login"}
            className={`${pillStyle.pillButton}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={loginSrc} alt={t("altText.icon")} />
            <span>{t("buttons.logIn")}</span>
          </a>
        }

      </div>
      <Modal show={showPromoteModal} centered onHide={() => setShowPromoteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("profile.promote.heading")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <p>{t("profile.promote.intro")}</p>
          <ProfileBadge profile={profile} />
          <br />

          <p>{t("profile.promote.instructions")}</p>
          <textarea
            className={textarea.codeBlock}
            onClick={(e) => (e.target as HTMLTextAreaElement).select()}
            readOnly
          >
            {embedCode}
          </textarea>
          <button
            className={pillStyle.pillButton}
            onClick={() => copyText(embedCode, () => setCopied(true))}
          >
            <img src={copied ? checkmarkIconSrc : clipboardIconSrc} alt={t("altText.icon")} />
            {copied ? t("buttons.copied") : t("buttons.copy")}
          </button>
        </Modal.Body>
      </Modal>
    </>
  )
}
