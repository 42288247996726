import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Helmet from "react-helmet"
import Container, { ContainerSize } from "../Container/Container"
import { CurrentUser, fetchCurrentUser, fetchProfile, Profile, Spinner } from "@simplebooth/webkit"
import DefaultCoverPhotoSrc from "../../image/default-cover-photo.jpg"
import DefaultAvatarSrc from "../../image/avatar-robot.svg"
import stylesheet from "./ProfilePage.module.scss"
import { ReactComponent as CalendarIcon } from "../../image/calendar.svg"
import { ReactComponent as LinkIcon } from "../../image/link.svg"
import { ReactComponent as FlagIcon } from "../../image/flag.svg"
import { ReactComponent as TopUserIcon } from "../../image/trophy.svg"
import SimpleBoothLogo from "../../image/simple-booth-logo-black.svg"
import ProfileGallerySection from "./ProfileGallerySection/ProfileGallerySection"
import ProfileControls from "./ProfileControls/ProfileControls"
import buttonStyles from "../Button/Button.module.scss"
import { MARKETING_WEBSITE_URL } from "../../library/globals"
import InternalServerErrorState from "../ErrorState/InternalServerErrorState"
import DefaultErrorState from "../ErrorState/DefaultErrorState"
import ProfileNotFoundState from "../ErrorState/ProfileNotFoundState"
import { useTranslation } from "react-i18next"
import ReactGA from "react-ga"
import { Modal } from "react-bootstrap"

export default function ProfilePage() {
  const [profile, setProfile] = useState<Profile | null>(null)
  const [errorCode, setErrorCode] = useState<number | null>(null)
  const [showFullDescription, setShowFullDescription] = useState<boolean>(false)
  const [showAvatarModal, setShowAvatarModal] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null)
  const [currentUserErrorCode, setCurrentUserErrorCode] = useState<number | null>(null)

  const { t, i18n } = useTranslation()

  let { username } = useParams()

  useEffect(() => {
    if (!username) return

    fetchProfile(
      username,
      setProfile,
      setErrorCode
    )

    fetchCurrentUser(setCurrentUser, setCurrentUserErrorCode)
  }, [username])

  if (errorCode) {
    switch (errorCode) {
      case 404:
        return <ProfileNotFoundState />
      case 500:
        return <InternalServerErrorState />
      default:
        return <DefaultErrorState errorCode={errorCode} />
    }
  }

  if (!profile) {
    return <Spinner centerVertical />
  }

  const description = profile.description ?? ""
  const shortenDescription = description.length > 150
  const canEdit = !currentUserErrorCode && currentUser?.username === profile.username

  let websiteDisplayName: string | null = null
  if (profile.website) {
    try {
      websiteDisplayName = (new URL(profile.website)).hostname.replace("www.", "")
    } catch { }
  }

  return (
    <>
      <Helmet>
        <title>{profile.displayName} | {profile.username} on Simple Booth</title>
      </Helmet>
      <div className={stylesheet.coverPhoto} style={{ backgroundImage: `url(${profile.coverImage?.url ?? DefaultCoverPhotoSrc})` }}>
      </div>

      <a
        className={stylesheet.logo}
        onClick={() => {
          ReactGA.event({ category: "Profile", action: "Click Simple Booth Logo" })
        }}
        href={MARKETING_WEBSITE_URL}
      >
        <img src={SimpleBoothLogo} alt={t("altText.logo")} />
      </a>

      <ProfileControls
        profile={profile}
        loggedIn={currentUser !== null}
        canEdit={canEdit}
      />

      <Container size={ContainerSize.MEDIUM} className={stylesheet.page}>
        <div className={stylesheet.userInfo}>
          <div className={stylesheet.groupLeft}>
            <div className={stylesheet.avatar}
              style={{ backgroundImage: `url(${profile.avatar?.url ?? DefaultAvatarSrc})` }}
              onClick={() => setShowAvatarModal(true)}
            >
            </div>
            <div>
              <h1 className={stylesheet.name}>{profile.displayName ?? profile.company}</h1>
              <span className={stylesheet.username}>
                @{profile.username}
              </span>
            </div>
          </div>

          {profile.actionButton &&
            <a
              className={`${buttonStyles.blue} ${stylesheet.actionButton}`}
              onClick={() => {
                ReactGA.event({ category: "Profile", action: "Click Action Button", label: profile.actionButton?.type })
              }}
              href={profile.actionButton.url.toString()}
            >
              {t(`profile.actionButton.${profile.actionButton.type}`)}
            </a>
          }
        </div>

        <div className={stylesheet.description}>
          <p>
            {(shortenDescription && !showFullDescription)
              ? <>
                {`${description.slice(0, 140)}... `}
                <button
                  className={buttonStyles.asLink}
                  onClick={() => setShowFullDescription(true)}>more</button>
              </>
              : description}
          </p>
        </div>

        <div className={stylesheet.tidbits}>
          {profile.website && websiteDisplayName &&
            <span>
              <LinkIcon />
              <a
                className={buttonStyles.asLink}
                onClick={() => {
                  ReactGA.event({ category: "Profile", action: "Click Profile Website", label: websiteDisplayName ?? undefined })
                }}
                href={profile.website}
              >
                {websiteDisplayName}
              </a>
            </span>
          }
          <span><CalendarIcon />
            {
              t('profile.joinedDate', {
                date: profile.createdAt.toLocaleDateString(i18n.language, { year: 'numeric', month: 'short' })
              })
            }
          </span>
          {profile.industry &&
            <span><FlagIcon />{t(`industries.${profile.industry}`)}</span>
          }
          {profile.numberOfUploads > 5000 &&
            <span><TopUserIcon />{t("profile.topUser")}</span>
          }
        </div>

        <div className={stylesheet.galleries}>
          <ProfileGallerySection profile={profile} />
        </div>

        <Modal show={showAvatarModal} centered onHide={() => setShowAvatarModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{profile.displayName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={profile.avatar?.url} alt={t("altText.profileAvatar")} />
          </Modal.Body>
        </Modal>
      </Container>
    </>
  )
}
