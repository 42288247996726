import styles from "./AdCard.module.scss"
import Ad from "../../../library/Ad"
import animations from "../../../styles/Animations.module.scss"

interface AdCardProps {
  ad: Ad
  small?: boolean
}

export default function AdCard({ ad, small }: AdCardProps) {
  return (
    <div
      className={`${styles.card} ${small ? styles.small : styles.large}`}
      style={{ color: ad.textColor, backgroundColor: ad.backgroundColor }}
    >
      {ad.headerImage &&
        <img
          className={styles.headerImage}
          src={ad.headerImage.url}
          style={ad.headerImageStyles ?? {}}
          alt="Graphic"
        />
      }
      <div className={styles.text}>
        {ad.text}
      </div>
      {ad.callToAction &&
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          className={`${styles.callToAction} ${animations.hoverScale}`}
          style={{ color: ad.callToAction.textColor, backgroundColor: ad.callToAction.backgroundColor }}
          href={ad.callToAction.href}
          target="_blank"
        >
          {ad.callToAction.title}
        </a>
      }
    </div>
  )
}
