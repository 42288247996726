import DatePicker from 'react-datepicker'
import PillButton from "../../Button/PillButton.module.scss"
import { ReactComponent as TrendingIcon } from "../../../image/trending.svg"
import { ReactComponent as DateIcon } from "../../../image/calendar.svg"
import { ReactComponent as CloseIcon } from "../../../image/close.svg"
import "./DatePicker.css"
import { Gallery, GallerySortType } from '@simplebooth/webkit'
import stylesheet from "./FeedControls.module.scss"
import { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

interface FeedControlsProps {
  gallery: Gallery
  sorting: GallerySortType
  date: Date | null
  sortByDate: (date: Date) => void
  sortByTrending: () => void
  sortByNewest: () => void
}

export default function FeedControls({ gallery, sorting, date, sortByDate, sortByNewest, sortByTrending }: FeedControlsProps) {
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [datePickerOffset, setDatePickerOffset] = useState<string | null>(null)

  const dateButtonRef = useRef<HTMLDivElement>(null)
  const controlsRef = useRef<HTMLDivElement>(null)
  const calendarContainer = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement

      if (!target
        || target === calendarContainer.current
        || calendarContainer.current?.contains(target)
        || dateButtonRef.current?.contains(target)) {
        return
      }

      setShowDatePicker(false)
    }

    document.addEventListener("mousedown", handleClick, false);
    return () => { document.removeEventListener("mousedown", handleClick, false); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (showDatePicker) {
      if (!dateButtonRef.current || !controlsRef.current || !calendarContainer.current) return
      let offset = dateButtonRef.current.offsetLeft - ((calendarContainer.current.offsetWidth - dateButtonRef.current.offsetWidth) / 2)
      setDatePickerOffset(`${offset}px`)
    } else {
      setDatePickerOffset(null)
    }
  }, [showDatePicker])

  const parseGalleryDate = (isoDateString: string) => {
    let [year, month, day] = isoDateString.split("-")
    let utcDate = Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day), 23, 59, 59)
    return new Date(utcDate)
  }

  const showTrendingButton = gallery.totalUploads > 100
  const showDateButton = gallery.dates && gallery.dates.length > 1
  const activeStyle = {
    backgroundColor: gallery.getBackgroundContrastColor(),
    borderColor: gallery.getBackgroundContrastColor()
  }

  if (!showDateButton && !showTrendingButton && !showDateButton) {
    return <></>
  }

  return (
    <div className={stylesheet.controls} ref={controlsRef}>
      {showDateButton && <>
        <div
          className={`${PillButton.multibutton} ${sorting === GallerySortType.DATES ? stylesheet.active : stylesheet.inactive}`}
          style={sorting === GallerySortType.DATES ? activeStyle : {}}
          ref={dateButtonRef}
        >
          <button
            className={`${PillButton.innerMultibutton} ${sorting === GallerySortType.DATES ? stylesheet.activeText : stylesheet.inactiveText}`}
            onClick={() => setShowDatePicker(!showDatePicker)}
            style={date ? { borderTopRightRadius: "0px", borderBottomRightRadius: "0px" } : {}}>
            <DateIcon className={PillButton.svg} />{date ? date?.toLocaleDateString() : t("feed.date")}
          </button>
          {sorting === GallerySortType.DATES &&
            <button
              className={`${PillButton.innerMultibutton} ${stylesheet.activeText}`}
              style={{ borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }}
              onClick={(event) => {
                event.preventDefault()
                sortByNewest()
              }}>
              <CloseIcon className={stylesheet.buttonCancelSvg} />
            </button>
          }
        </div>

        {showDatePicker && gallery.dates &&
          <div
            ref={calendarContainer}
            className={stylesheet.calendarContainer}
            style={{ left: datePickerOffset ? datePickerOffset : "0px", opacity: datePickerOffset ? "1" : "0" }}
          >
            <DatePicker
              disabledKeyboardNavigation
              selected={date}
              inline
              includeDates={gallery.dates.map(dateStr => parseGalleryDate(dateStr))}
              showPopperArrow={false}
              onChange={(object: any) => {
                setShowDatePicker(false)
                sortByDate(new Date(object))
              }}
            />
          </div>
        }
      </>
      }

      {showTrendingButton &&
        <button
          className={`${PillButton.pillButton} ${sorting === GallerySortType.POPULAR ? stylesheet.active : stylesheet.inactive}`}
          style={sorting === GallerySortType.POPULAR ? activeStyle: {}}
          onClick={sorting !== GallerySortType.POPULAR ? sortByTrending : sortByNewest}
        >
          <TrendingIcon />{t("feed.trending")}
        </button>
      }
    </div>
  )
}