import EmptyState from '../EmptyState/EmptyState'
import { IconWithConfetti } from "@simplebooth/webkit"
import { ReactComponent as Exclamation } from "../../image/exclamation.svg"
import { useTranslation } from 'react-i18next'
import { SUPPORT_EMAIL } from '../../library/globals'

interface DefaultErrorStateProps {
  errorCode: number
}

export default function DefaultErrorState({ errorCode }: DefaultErrorStateProps) {
  const { t } = useTranslation()

  return (
    <EmptyState fullscreen>
      <IconWithConfetti style={{ height: "340px", width: "100%" }}>
        <Exclamation />
      </IconWithConfetti>
      <h1>
        {t('errorState.anyCode.heading', { errorCode: errorCode })}
      </h1>
      <p>
        {t('errorState.anyCode.text', { supportEmail: SUPPORT_EMAIL })}
      </p>
    </EmptyState>
  )
}
