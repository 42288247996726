/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Gallery, Upload } from "@simplebooth/webkit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InView from "react-intersection-observer";
import { Link } from "react-router-dom";
import { timeSince } from "../../../../library/DateTimeUtil";
import Showcase from "../GalleryCardShowcase/GalleryCardShowcase";
import stylesheet from "./GalleryCard.module.scss"

interface GalleryCardProps {
  gallery: Gallery
  didLoad?: () => void
}

export default function GalleryCard({ gallery, didLoad }: GalleryCardProps) {
  const [latestUploads, setLatestUploads] = useState<Upload[] | null>(null)
  const [errorCode, setErrorCode] = useState<number | null>(null)
  const [isReady, setIsReady] = useState(false)
  const [inView, setInView] = useState(false)

  const { t, i18n } = useTranslation()

  const showHappeningNowTimeframe = 30 // minutes

  useEffect(() => {
    gallery.fetchLatestUploads(setLatestUploads, setErrorCode)
  }, [gallery])

  let titleAsHashTag = `#${gallery.title.replaceAll(" ", "").replaceAll("'", "")}`
  let isHappeningNow = false
  if (gallery.latestUploadCreatedAt) {
    let secondsAgo = (Date.now() - gallery.latestUploadCreatedAt.getTime()) / 1000
    isHappeningNow = secondsAgo < showHappeningNowTimeframe * 60
  }

  return (
    <Link to={`/gallery/${gallery.code}`}>
      <InView threshold={0.2} onChange={setInView}>
        <div className={`${stylesheet.galleryCard} ${isReady ? stylesheet.loaded : stylesheet.loading}`}>
          {latestUploads &&
            <>
              <div className={stylesheet.cardHeader}>
                <div className={stylesheet.spaceBetween}>
                  <h3>{gallery.title}</h3>
                  {isHappeningNow &&
                    <span className={stylesheet.happeningNow}>{t("profile.galleryCard.happeningNow")}</span>
                  }
                </div>
                <div className={`${stylesheet.spaceBetween} ${stylesheet.secondaryInfo}`}>
                  <span>
                    {gallery.hashtag || titleAsHashTag}
                  </span>
                  <span>
                    <span>
                      {t('profile.galleryCard.photoCount',
                        {
                          count: gallery.totalUploads,
                          formattedCount: Intl.NumberFormat(i18n.language).format(gallery.totalUploads)
                        }
                      )}
                    </span>
                    <span className={stylesheet.divider}>|</span>
                    <span>{timeSince(gallery.latestUploadCreatedAt?.getTime() ?? latestUploads[0].createdAt)}</span>
                  </span>
                </div>
              </div>
              <Showcase
                latestUploads={latestUploads}
                inView={inView}
                didLoadCallback={() => {
                  if (!isReady) {
                    setIsReady(true)
                    if (didLoad) didLoad()
                  }
                }}
              />
            </>
          }
          {errorCode &&
            <p>
              {t('errorState.anyCode.heading', { errorCode: errorCode })}
            </p>
          }
        </div>
      </InView>
    </Link>
  )
}
