import ConfettiPiece, { ConfettiColor, ConfettiSize, ConfettiType } from "../ConfettiPiece/ConfettiPiece"
import stylesheet from "./ConfettiField.module.scss"

export default function ConfettiField({ play }: {play: boolean}) {
  return (
    <>
      {/* Top left */}
      <ConfettiPiece
        type={ConfettiType.SQUIGGLE}
        size={ConfettiSize.LARGE}
        color={ConfettiColor.BLUE}
        position={{ left: "5%", top: "10%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.CIRCLE}
        size={ConfettiSize.SMALL}
        color={ConfettiColor.YELLOW}
        position={{ left: "25%", top: "3%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.CIRCLE}
        size={ConfettiSize.LARGE}
        color={ConfettiColor.ORANGE}
        position={{ left: "9%", top: "45%" }}
        reverseAnimation
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUARE}
        size={ConfettiSize.SMALL}
        color={ConfettiColor.GREEN}
        position={{ left: "16%", top: "27%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUIGGLE}
        size={ConfettiSize.MEDIUM}
        color={ConfettiColor.ORANGE}
        position={{ left: "23%", top: "18%" }}
        className={stylesheet.hideOnMobile}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.CIRCLE}
        size={ConfettiSize.SMALL}
        color={ConfettiColor.ORANGE}
        position={{ left: "35%", top: "9%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUARE}
        size={ConfettiSize.LARGE}
        color={ConfettiColor.BLUE}
        position={{ left: "38%", top: "20%" }}
        className={stylesheet.hideOnMobile}
        play={play}
      />

      {/* Top right */}
      <ConfettiPiece
        type={ConfettiType.SQUIGGLE}
        size={ConfettiSize.SMALL}
        color={ConfettiColor.GREEN}
        position={{ left: "58%", top: "18%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.CIRCLE}
        size={ConfettiSize.LARGE}
        color={ConfettiColor.PURPLE}
        position={{ left: "75%", top: "12%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUARE}
        size={ConfettiSize.MEDIUM}
        color={ConfettiColor.GREEN}
        position={{ left: "65%", top: "-30px" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUIGGLE}
        size={ConfettiSize.MEDIUM}
        color={ConfettiColor.YELLOW}
        position={{ left: "85%", top: "4%" }}
        reverseAnimation
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUIGGLE}
        size={ConfettiSize.MEDIUM}
        color={ConfettiColor.ORANGE}
        position={{ left: "95%", top: "18%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUIGGLE}
        size={ConfettiSize.MEDIUM}
        color={ConfettiColor.BLUE}
        position={{ left: "74%", top: "33%" }}
        reverseAnimation
        className={stylesheet.hideOnMobile}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUARE}
        size={ConfettiSize.MEDIUM}
        color={ConfettiColor.BLUE}
        position={{ left: "90%", top: "42%" }}
        reverseAnimation
        play={play}
      />

      {/* Bottom left */}
      <ConfettiPiece
        type={ConfettiType.SQUIGGLE}
        size={ConfettiSize.MEDIUM}
        color={ConfettiColor.PURPLE}
        position={{ left: "18%", top: "60%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUARE}
        size={ConfettiSize.LARGE}
        color={ConfettiColor.GREEN}
        position={{ left: "3%", top: "75%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.CIRCLE}
        size={ConfettiSize.MEDIUM}
        color={ConfettiColor.BLUE}
        position={{ left: "18%", top: "98%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUARE}
        size={ConfettiSize.SMALL}
        color={ConfettiColor.YELLOW}
        position={{ left: "24%", top: "85%" }}
        reverseAnimation
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUIGGLE}
        size={ConfettiSize.SMALL}
        color={ConfettiColor.ORANGE}
        position={{ left: "33%", top: "94%" }}
        reverseAnimation
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUARE}
        size={ConfettiSize.SMALL}
        color={ConfettiColor.PURPLE}
        position={{ left: "7%", top: "102%" }}
        reverseAnimation
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUIGGLE}
        size={ConfettiSize.SMALL}
        color={ConfettiColor.YELLOW}
        position={{ left: "1%", top: "50%" }}
        reverseAnimation
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUARE}
        size={ConfettiSize.MEDIUM}
        color={ConfettiColor.GREEN}
        position={{ left: "42%", top: "102%" }}
        reverseAnimation
        play={play}
      />

      {/* Bottom right */}
      <ConfettiPiece
        type={ConfettiType.SQUIGGLE}
        size={ConfettiSize.LARGE}
        color={ConfettiColor.PURPLE}
        position={{ left: "95%", top: "80%" }}
        reverseAnimation
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUARE}
        size={ConfettiSize.LARGE}
        color={ConfettiColor.YELLOW}
        position={{ left: "75%", top: "60%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.CIRCLE}
        size={ConfettiSize.SMALL}
        color={ConfettiColor.ORANGE}
        position={{ left: "88%", top: "70%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUARE}
        size={ConfettiSize.MEDIUM}
        color={ConfettiColor.GREEN}
        position={{ left: "98.5%", top: "56%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUIGGLE}
        size={ConfettiSize.MEDIUM}
        color={ConfettiColor.GREEN}
        position={{ left: "76%", top: "90%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.CIRCLE}
        size={ConfettiSize.SMALL}
        color={ConfettiColor.YELLOW}
        position={{ left: "93%", top: "98%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.CIRCLE}
        size={ConfettiSize.LARGE}
        color={ConfettiColor.BLUE}
        position={{ left: "58%", top: "96%" }}
        play={play}
      />
      <ConfettiPiece
        type={ConfettiType.SQUARE}
        size={ConfettiSize.SMALL}
        color={ConfettiColor.BLUE}
        position={{ left: "85%", top: "102%" }}
        play={play}
      />
    </>
  )
}