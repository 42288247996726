/* eslint-disable react-hooks/exhaustive-deps */
import { GallerySortType, MediaQuality, MediaView, Upload, UploadLayout, trackView, DeviceUtil } from "@simplebooth/webkit"
import { timeSince } from "../../../library/DateTimeUtil"
import { CSSProperties, useState, useEffect, useRef } from "react"
import ShareIcon from "../../../image/share-gray.svg"
import PrivateIcon from "../../../image/action-set-private.svg"
import PlayIcon from "../../../image/play-video.svg"
import MediaPlaceholder from "../../MediaPlaceholder/MediaPlaceholder"
import { InView } from 'react-intersection-observer'
import stylesheet from "./UploadCard.module.scss"
import UploadControls from "./UploadControls/UploadControls"
import { useTranslation } from "react-i18next"
import ReactGA from "react-ga"

export enum SizeContext {
  MULTICOLUMN, SINGLE_COLUMN
}

interface UploadCardProps {
  upload: Upload
  nearViewport: boolean
  context: SizeContext
  sortedBy: GallerySortType
  editable: boolean
  admin: boolean
  style?: CSSProperties
  requestFullscreen?: (upload: Upload) => void
  handleReport: (upload: Upload) => void
  handleShare: (upload: Upload) => void
  handleRemoveCard: (upload: Upload) => void
  handleInView: (inView: boolean) => void
}

export default function UploadCard({ upload, nearViewport, context, style, sortedBy, editable, admin, requestFullscreen, handleShare, handleReport, handleRemoveCard, handleInView }: UploadCardProps) {
  const [showControls, setShowControls] = useState(false)
  const [isMediaReady, setIsMediaReady] = useState(false)
  const [isPendingDelete, setIsPendingDelete] = useState(false)
  const [inView, setInView] = useState(false)
  const [trackedView, setTrackedView] = useState(false)

  const { t, i18n } = useTranslation()

  const timerRef = useRef<NodeJS.Timeout>()
  const millisecondsToTrackView = 3000

  useEffect(() => {
    if (inView) {
      timerRef.current = setTimeout(handleTrackView, millisecondsToTrackView)
    } else if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  }, [inView, upload])

  useEffect(() => handleInView(inView), [inView])

  const handleTrackView = () => {
    if (trackedView) return
    if (editable) return
    if (context === SizeContext.MULTICOLUMN) return // Don't track views in grid mode for now

    setTrackedView(true)
    trackView(upload.code)
  }

  const selectQuality = (upload: Upload) => {
    if (upload.dimensions.aspect < 1 || upload.isGif()) {
      return context === SizeContext.SINGLE_COLUMN ? MediaQuality.HIGH : MediaQuality.MEDIUM
    }
    return context === SizeContext.SINGLE_COLUMN ? MediaQuality.MEDIUM : MediaQuality.LOW
  }

  const isLongformVideo = upload.layout === UploadLayout.VIDEO
  const isRebound = upload.layout === UploadLayout.REBOUND
  const isVideoPreview = isLongformVideo && context === SizeContext.MULTICOLUMN
  const allowFullscreen = (context === SizeContext.MULTICOLUMN && !DeviceUtil.isTouchDevice() && requestFullscreen != null) || isVideoPreview

  return (
    <InView threshold={0.5} onChange={setInView}>
      <div
        className={`${stylesheet.card} ${upload.dimensions.aspect < 0.5 ? stylesheet.photoStrip : ""} ${context === SizeContext.SINGLE_COLUMN ? stylesheet.singleColumn : stylesheet.multicolumn}`}
        onMouseOver={(_) => setShowControls(true)}
        onMouseLeave={(_) => setShowControls(false)}
        style={style}
      >
        <MediaPlaceholder
          className={stylesheet.mediaContainer}
          aspectRatio={upload.dimensions.aspect}
          playAnimation={!isMediaReady && inView}
        >
          {(inView || nearViewport || !upload.isVideo()) &&
            <MediaView
              className={`${stylesheet.mediaView} ${isMediaReady ? stylesheet.opaque : stylesheet.transparent} ${allowFullscreen ? stylesheet.clickable : ""}`}
              upload={upload}
              quality={selectQuality(upload)}
              videoOptions={{
                play: true,
                previewOnly: isVideoPreview,
                attributes: {
                  controls: isLongformVideo,
                  muted: DeviceUtil.isTouchDevice() || isRebound,
                  autoPlay: true,
                  playsInline: true,
                  loop: true
                }
              }}
              imgAttributes={{ loading: "eager" }}
              isReadyCallback={() => { setIsMediaReady(true) }}
              onClick={() => {
                if (allowFullscreen && requestFullscreen != null) {
                  requestFullscreen(upload)
                  ReactGA.event({ category: "Upload Card", action: "View Fullscreen" })
                }
              }}
            />
          }
          {isVideoPreview &&
            <div style={{ pointerEvents: "none" }}>
              <img
                src={PlayIcon}
                className={`${stylesheet.playIcon} ${showControls ? stylesheet.hover : ""}`}
                alt={t("altText.icon")}
              />
            </div>
          }
        </MediaPlaceholder>

        <div className={stylesheet.infoBar}>
          <div>
            {sortedBy === GallerySortType.NEWEST && timeSince(upload.createdAt)}
            {sortedBy === GallerySortType.POPULAR && upload.viewCount &&
              t('gallery.uploadCard.viewCount',
                {
                  count: upload.viewCount,
                  formattedCount: Intl.NumberFormat(i18n.language).format(upload.viewCount)
                }
              )
            }
            {sortedBy === GallerySortType.DATES &&
              new Date(upload.createdAt).toLocaleString(i18n.language, { dateStyle: "short", timeStyle: "short" })
            }
          </div>
          {upload.public ?
            <button
              className={stylesheet.shareButton}
              style={{ backgroundImage: `url(${ShareIcon})` }}
              onClick={() => {
                handleShare(upload)
                ReactGA.event({ category: "Upload Card", action: "Open Share Sheet" })
              }}
            >
            </button>
            :
            <img src={PrivateIcon} alt={t("altText.private")} title={t("altText.private")} />
          }
        </div>

        {(editable || admin) &&
          <UploadControls
            upload={upload}
            showButton={showControls}
            editable={editable}
            admin={admin}
            handleReport={() => handleReport(upload)}
            onMakePrivate={() => {
              ReactGA.event({ category: "Upload Card", action: "Make Private" })
              upload.public = false
            }}
            onDeletePending={() => setIsPendingDelete(true)}
            onDeleteCanceled={() => setIsPendingDelete(false)}
            onDelete={(theUpload) => {
              handleRemoveCard(theUpload)
              ReactGA.event({ category: "Upload Card", action: "Delete Upload" })
            }}
          />
        }

        {isPendingDelete &&
          <div className={stylesheet.pendingDelete}></div>
        }
      </div>
    </InView>
  )
}
