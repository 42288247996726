import { useEffect, useState } from 'react'
import { Upload, fetchUpload, Gallery, Spinner, GalleryPrivacy, Profile } from "@simplebooth/webkit"
import PicView from './PicView/PicView'
import Feed from '../Feed/Feed'
import { ContainerSize } from '../Container/Container'
import { ReactComponent as NewWindowIcon } from "../../image/new-window.svg"
import stylesheet from "./PicPage.module.scss"
import { useTranslation } from 'react-i18next'
import DefaultErrorState from '../ErrorState/DefaultErrorState'
import InternalServerErrorState from '../ErrorState/InternalServerErrorState'
import UploadNotFoundState from '../ErrorState/UploadNotFoundState'
import UploadDeletedState from '../ErrorState/UploadDeletedState'
import GalleryBrandedPage from '../GalleryBrandedPage/GalleryBrandedPage'
import { useParams } from 'react-router-dom'

export default function PicPage() {
  const [upload, setUpload] = useState<Upload | null>(null)
  const [gallery, setGallery] = useState<Gallery>(new Gallery())
  const [profile, setProfile] = useState<Profile | undefined>(undefined)
  const [numberOfColumns, setNumberOfColumns] = useState<number | null>(1)
  const [errorCode, setErrorCode] = useState<number | null>(null)

  const { picCode } = useParams()

  const { t } = useTranslation()

  useEffect(() => {
    if(!picCode) return

    fetchUpload(picCode, (uploadResource) => {
      let upload = Upload.fromResource(uploadResource)
      setUpload(upload)

      if (uploadResource._embedded && uploadResource._embedded.gallery) {
        let gallery = Gallery.fromResource(uploadResource._embedded.gallery)
        setGallery(gallery)

        let profileResource = uploadResource._embedded.gallery._embedded.profile
        if (profileResource /*&& profileResource.publi*/) {
          setProfile(Profile.fromResource(profileResource))
        }
      }
    }, setErrorCode)
  }, [picCode])

  if (errorCode) {
    switch (errorCode) {
      case 404:
        return <UploadNotFoundState />
      case 500:
        return <InternalServerErrorState />
      default:
        return <DefaultErrorState errorCode={errorCode} />
    }
  }

  if (!upload) {
    return <Spinner centerVertical />
  }

  if (upload.deleted) {
    return <UploadDeletedState galleryCode={gallery.code} />
  }

  return (
    <GalleryBrandedPage
      gallery={gallery}
      profile={profile}
      containerSize={(numberOfColumns ?? 1) > 1 ? ContainerSize.LARGE : ContainerSize.SMALL}
    >
      <PicView
        upload={upload}
        gallery={gallery}
      />
      {gallery.code && gallery.totalUploads > 1 &&
        <>
          <h2 className={stylesheet.introduction} style={{ color: gallery.design.textColor }}>
            {t("pic.feedHeading")}
            {gallery.links.webSelf &&
              <a href={new URL(gallery.links.webSelf).pathname} target="_blank" rel="noreferrer">
                <NewWindowIcon style={{ fill: gallery.hasDefaultColors() ? "#979797" : gallery.design.textColor }} />
              </a>
            }
          </h2>
          {gallery.privacy === GalleryPrivacy.PUBLIC &&
            <Feed
              gallery={gallery}
              profile={profile}
              numberOfColumns={numberOfColumns}
              setNumberOfColumns={setNumberOfColumns}
              excludeUploadCode={upload.code}
            />
          }
        </>
      }
    </GalleryBrandedPage>
  )
}