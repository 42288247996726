import { ReactNode } from "react";
import { Trans } from "react-i18next";

export function timeSince(timestamp: number): ReactNode {

  const intervals = [
    { i18nKey: 'timeSince.year', seconds: 31536000 },
    { i18nKey: 'timeSince.month', seconds: 2592000 },
    { i18nKey: 'timeSince.day', seconds: 86400 },
    { i18nKey: 'timeSince.hour', seconds: 3600 },
    { i18nKey: 'timeSince.minute', seconds: 60 },
    { i18nKey: 'timeSince.second', seconds: 1 }
  ];

  const seconds = Math.floor((Date.now() - timestamp) / 1000)
  const interval = intervals.find(i => i.seconds < seconds)

  if (interval) {
    let count = seconds / interval.seconds

    // Round for anything longer than an hour, otherwise use floor
    if (interval.seconds > 3600) {
      count = Math.round(count)
    } else {
      count = Math.floor(count)
    }

    if (seconds <= 30) {
      return <Trans i18nKey="timeSince.justNow"></Trans>
    } else {
      return <Trans i18nKey={interval.i18nKey} values={{ count: count }}></Trans>
    }
  }

  return null
}

export function parseGalleryDateString(isoDateString: string) {
  let [year, month, day] = isoDateString.split("-")
  let utcDate = Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day), 23, 59, 59)
  return new Date(utcDate)
}

export function resolveGalleryDateString(date: Date) {
  let year = date.getUTCFullYear().toString()
  let month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  let day = date.getUTCDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}`
}
