import { Profile } from "@simplebooth/webkit"
import { useState, useEffect, useMemo } from "react"
import ScrollingCallout from './ScrollingCallout/ScrollingCallout'
import stylesheet from "./ProfileLinks.module.scss"
import { Link } from "react-router-dom"
import ReactGA from "react-ga"
import throttle from 'lodash.throttle'

interface ProfileLinksProps {
  profile: Profile
}

export default function ProfileLinks({ profile }: ProfileLinksProps) {
  const [showCallout, setShowCallout] = useState(false)
  const [sentGaEvent, setSentGaEvent] = useState(false)

  const handleScroll = () => {
    setShowCallout(
      profile
      && window.scrollY > 800
      && (profile.public === undefined || profile.public)
    )
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledHandleScroll = useMemo(() => throttle(handleScroll, 300), [profile])

  useEffect(() => {
    window.addEventListener("scroll", throttledHandleScroll)
    return () => window.removeEventListener("scroll", throttledHandleScroll)
  })

  useEffect(() => {
    if (showCallout && !sentGaEvent) {
      ReactGA.event({ category: "Profile Links", action: "Show Profile Callout", label: profile.username })
      setSentGaEvent(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, showCallout])

  if (profile.public !== undefined && profile.public === false) {
    return <></>
  }

  return (
    <>
      <ScrollingCallout
        show={showCallout}
        profile={profile}
      />
      <div className={stylesheet.avatar}>
        <Link
          to={`/profiles/${profile.username.toLowerCase()}`}
          onClick={() => ReactGA.event({ category: "Profile Links", action: "Open Profile from Top Avatar", label: profile.username })}
        >
          <img src={profile.avatar?.url} alt="Profile avatar" />
        </Link>
      </div>
    </>
  )
}