/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { Gallery, fetchGallery, Spinner, IconWithConfetti, GallerySortType, Profile } from "@simplebooth/webkit"
import { ReactComponent as EmptyGallery } from "../../image/empty-gallery.svg"
import { useParams } from 'react-router-dom'
import Feed from '../Feed/Feed'
import { ContainerSize } from '../Container/Container'
import EmptyState from '../EmptyState/EmptyState'
import DefaultErrorState from '../ErrorState/DefaultErrorState'
import GalleryControls from './GalleryControls/GalleryControls'
import InternalServerErrorState from '../ErrorState/InternalServerErrorState'
import GalleryNotFoundState from '../ErrorState/GalleryNotFoundState'
import GalleryPrivateState from '../ErrorState/GalleryPrivateState'
import GalleryBrandedPage from '../GalleryBrandedPage/GalleryBrandedPage'
import { useTranslation } from 'react-i18next'
import { parseGalleryDateString } from '../../library/DateTimeUtil'
import GalleryDeletedState from "../ErrorState/GalleryDeletedState"

export default function GalleryPage() {
  const [gallery, setGallery] = useState<Gallery | null>(null)
  const [profile, setProfile] = useState<Profile | undefined>(undefined)
  const [numberOfColumns, setNumberOfColumns] = useState<number | null>(null)
  const [errorCode, setErrorCode] = useState<number | null>(null)

  const { t } = useTranslation()

  const params = useParams()
  const { friendlyUrl, sorting } = params

  let date = params.date ? parseGalleryDateString(params.date) : null

  useEffect(() => {
    if (!friendlyUrl) return

    const galleryCode = friendlyUrl.split("-")[0] // Extract code from friendly URL

    if (gallery && galleryCode === gallery.code) return // Only fetch same gallery once

    fetchGallery(
      galleryCode,
      GallerySortType.NEWEST,
      16,
      null,
      (galleryResource) => {
        setGallery(Gallery.fromLegacyResource(galleryResource))

        let profileResource = galleryResource._embedded.profile
        if (profileResource /*&& profileResource.public*/) {
          setProfile(Profile.fromResource(profileResource))
        }
      },
      setErrorCode
    )
  }, [friendlyUrl])

  if (errorCode) {
    switch (errorCode) {
      case 401:
        return <GalleryPrivateState />
      case 404:
        return <GalleryNotFoundState />
      case 410:
        return <GalleryDeletedState />
      case 500:
        return <InternalServerErrorState />
      default:
        return <DefaultErrorState errorCode={errorCode} />
    }
  }

  if (!gallery) {
    return <Spinner centerVertical />
  }

  return (
    <GalleryBrandedPage
      gallery={gallery}
      profile={profile}
      containerSize={(numberOfColumns ?? 1) > 1 ? ContainerSize.LARGE : ContainerSize.SMALL}
      paddingForControls
    >
      {gallery.totalUploads > 0 ?
        <Feed
          gallery={gallery}
          profile={profile}
          numberOfColumns={numberOfColumns}
          setNumberOfColumns={setNumberOfColumns}
          defaultSorting={sorting as GallerySortType}
          defaultSortingDate={date}
          onGalleryPage
        />
        :
        <EmptyState asCard style={{ marginTop: "4vh" }}>
          <IconWithConfetti largeIcon largeShadow style={{ height: "min(360px, 65vw)", width: "100%" }}>
            <EmptyGallery />
          </IconWithConfetti>
          <h1>{t("gallery.empty.heading")}</h1>
          <p>{t("gallery.empty.text")}</p>
        </EmptyState>
      }

      <GalleryControls gallery={gallery} />
    </GalleryBrandedPage>
  )
}
