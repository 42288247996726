import EmptyState from '../EmptyState/EmptyState'
import { IconWithConfetti } from "@simplebooth/webkit"
import { ReactComponent as FrumpyGhost } from "../../image/frumpy-ghost.svg"
import BlueButton from '../Button/BlueButton'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function UploadDeletedState({ galleryCode }: { galleryCode?: string | null }) {
  const { t } = useTranslation()

  return (
    <EmptyState fullscreen>
      <IconWithConfetti largeShadow style={{ height: "340px", width: "100%" }}>
        <FrumpyGhost />
      </IconWithConfetti>
      <h1>{t("pic.deleted.heading")}</h1>
      <p>
        <Trans i18nKey="pic.deleted.text">
          This photo has been removed. <a href="https://help.simplebooth.com/en/articles/1686763-my-picture-is-missing-or-has-been-removed-what-can-i-do" target="_blank" rel="noreferrer">More info</a>
        </Trans>
      </p>
      {galleryCode &&
        <Link to={`/gallery/${galleryCode}`}><BlueButton>{t("pic.deleted.buttonTitle")}</BlueButton></Link>
      }
    </EmptyState>
  )
}
