/* eslint-disable react-hooks/exhaustive-deps */
import { MediaQuality, MediaView, Upload, UploadUtil } from "@simplebooth/webkit"
import { useEffect, useRef, useState } from "react"
import stylesheet from "./GalleryCardShowcase.module.scss"

interface ShowcaseProps {
  latestUploads: Upload[]
  inView: boolean
  didLoadCallback: () => void
}

interface Size {
  width: number,
  height: number
}

export default function Showcase({ latestUploads, inView, didLoadCallback }: ShowcaseProps) {
  const [displayUploads, setDisplayUploads] = useState<Upload[]>([])
  const [numberOfColumns, setNumberOfColumns] = useState<number | null>(null)
  const [numberOfRows, setNumberOfRows] = useState<number>(1)

  // Ideally we would wait until all the media is loaded, but there is some problem updating state from the callback that blocked me
  const [atLeastOneMediaLoaded, setAtLeastOneMediaLoaded] = useState<boolean>(false)
  
  const [isLoaded, setIsLoaded] = useState(false)
  const [cameIntoView, setCameIntoView] = useState(false)
  const [itemSize, setItemSize] = useState<Size>({ width: 1, height: 1 })

  const showcaseRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (inView) setCameIntoView(true)
  }, [inView])

  useEffect(() => {
    if (atLeastOneMediaLoaded && !isLoaded) {
      setIsLoaded(true)
      didLoadCallback()
    }
  }, [atLeastOneMediaLoaded])

  const updateShowcaseLayout = () => {
    if (!latestUploads) return

    if (latestUploads.length === 0) return setNumberOfColumns(0)

    let isWidescreen = window.innerWidth > 720
    let targetAspectRatio = isWidescreen ? 3.5 : 1

    let uploadAspectRatio = UploadUtil.getUploadAspectRatio(latestUploads)
    let layout = UploadUtil.calculateLayoutToFit(uploadAspectRatio, targetAspectRatio, 3)

    if (isWidescreen) {
      layout.columns = Math.round(layout.columns / layout.rows)
      layout.rows = 1
    }

    setNumberOfColumns(layout.columns)
    setNumberOfRows(layout.rows)
    setDisplayUploads(
      latestUploads.slice(0, layout.rows * layout.columns) ?? []
    )

    if (showcaseRef.current) {
      let itemWidth = (showcaseRef.current.clientWidth / layout.columns)
      setItemSize({ width: itemWidth, height: itemWidth / uploadAspectRatio })
    }
  }

  useEffect(() => {
    window.addEventListener("resize", updateShowcaseLayout);
    return () => window.removeEventListener("resize", updateShowcaseLayout);
  }, [])

  useEffect(updateShowcaseLayout, [latestUploads, isLoaded])

  if (!numberOfColumns) {
    return <></>
  }

  return (
    <div
      className={stylesheet.showcase}
      ref={showcaseRef}
      style={{
        gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)`,
        gridTemplateRows: `repeat(${numberOfRows}, 1fr)`,
        height: (itemSize.height * numberOfRows) + "px"
      }}
    >
      {displayUploads.map((upload, index) => (
        <div key={upload.code}>
          <MediaView
            upload={upload}
            className={`${stylesheet.media} ${isLoaded && cameIntoView ? stylesheet.mediaAppear : ""}`}
            style={{ animationDelay: `${index * 60}ms`, height: itemSize.height + "px" }}
            quality={MediaQuality.LOW}
            videoOptions={{
              play: true,
              attributes: {
                muted: true,
                controls: false,
                autoPlay: true,
                loop: true,
                playsInline: true
              }
            }}
            imgAttributes={{ loading: "eager" }}
            isReadyCallback={() => {
              setAtLeastOneMediaLoaded(true)
            }}
          />
        </div>
      )
      )}
    </div>
  )
}