
import ImageAsset from "@simplebooth/webkit/build/library/common/ImageAsset"
import DefaultLogo from "../../image/simple-booth-logo-black.svg"
import headerStyles from "./BrandedHeader.module.scss"
import animations from "../../styles/Animations.module.scss"
import { MARKETING_WEBSITE_URL } from "../../library/globals"
import ReactGA from "react-ga"

interface HeaderProps {
  showDefaultLogo?: boolean | null
  imageAsset?: ImageAsset | null
  imageHref?: string | null
  imageMaxWidth?: number // 0-100
  heading?: string
  subheading?: string
  textColor?: string
}

export default function BrandedHeader({ showDefaultLogo, imageAsset, imageHref, heading, subheading, textColor, imageMaxWidth }: HeaderProps) {
  let headerImage = <></>
  if (showDefaultLogo) {
    headerImage = <img
      className={`${headerStyles.defaultLogo} ${animations.hoverScale}`}
      src={DefaultLogo}
      onClick={() => {
        ReactGA.event({ category: "Branded Header", action: "Click Simple Booth header image" })
        window.location.href = MARKETING_WEBSITE_URL
      }}
      alt="Simple Booth Logo"
    />
  }

  if (imageAsset) {
    headerImage = <img
      className={headerStyles.headerImage}
      src={imageAsset.url} alt="Gallery Branding"
      onClick={() => {
        if (imageHref) {
          ReactGA.event({ category: "Branded Header", action: "Click custom header image with link", label: imageHref })
          window.location.href = imageHref
        }
      }}
      loading="eager"
      style={{ maxWidth: imageMaxWidth ?? 100 + "%" }}
    />
  }

  let textStyles = textColor ? { color: textColor } : {}

  return (
    <header className={headerStyles.header}>
      {headerImage}
      {heading &&
        <h1 className={headerStyles.heading} style={textStyles}>
          {heading}
        </h1>
      }
      {subheading &&
        <h2 className={headerStyles.subheading} style={textStyles}>
          {subheading}
        </h2>
      }
    </header>
  )
}