import { ReactNode } from "react"
import stylesheet from "./ScrollUpButton.module.scss"
import ReactGA from "react-ga"

interface ScrollUpButtonProps {
  show: boolean
  scrollToElement?: HTMLElement
  scrollOffset?: number
  children?: ReactNode
}

export default function ScrollUpButton({ show, scrollToElement, scrollOffset, children }: ScrollUpButtonProps) {

  const scrollToTop = (animate: boolean) => {
    ReactGA.event({ category: "Feed", action: "Click scroll to top button" })

    let y = 0

    if (scrollToElement) {
      y = scrollToElement.getBoundingClientRect().top + window.pageYOffset + (scrollOffset ?? 0)
    }
    window.scrollTo({ top: y, behavior: animate ? "smooth" : "auto" })
  }

  return (
    <button
      className={`${stylesheet.newPhotosButton} ${show ? stylesheet.show : stylesheet.hide}`}
      onClick={() => scrollToTop(true)}>
      {children}
    </button>
  )
}
