import AppRoutes from "./AppRoutes"
import { API } from "@simplebooth/webkit"
import { Suspense } from "react"
import { BrowserRouter } from "react-router-dom"

export default function App() {
  const baseUrl = (window as any).social?.config?.api?.baseUrl ?? process.env.REACT_APP_API_BASE_URL as string
  API.configure(baseUrl)

  return (
    <Suspense fallback={<></>}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </Suspense>
  )
}
