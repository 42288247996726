import { API, Gallery, GalleryPrivacy } from "@simplebooth/webkit"
import PrivacyLock from "../../../image/lock-filled.svg"
import {useEffect, useState} from "react"
import stylesheet from "./GalleryControls.module.scss"
import playIconSrc from "../../../image/play.svg"
import EmbedIcon from "../../../image/window.svg"
import clipboardIconSrc from "../../../image/clipboard.svg"
import checkmarkIconSrc from "../../../image/checkmark.svg"
import shareIconSrc from "../../../image/share.svg"
import editIconSrc from "../../../image/edit.svg"
import pillStyle from "../../Button/PillButton.module.scss"
import textarea from "../../../styles/TextArea.module.scss"
import { Modal } from "react-bootstrap"
import { copyText } from "../../../library/CopyUtil"
import { useTranslation } from "react-i18next"
import ReactGA from "react-ga"

interface GalleryControlsProps {
  gallery: Gallery
}

export default function GalleryControls({ gallery }: GalleryControlsProps) {
  const [showPrivacyMessage, setShowPrivacyMessage] = useState(false)
  const [showEmbedModal, setShowEmbedModal] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [copied, setCopied] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    if (!showShareModal && !showEmbedModal) {
      setCopied(false)
    }
  }, [showShareModal, showEmbedModal])

  const linkProps = {
    className: `${pillStyle.pillButton} ${pillStyle.mobileCollapse}`,
    target: "_blank",
    rel: "noreferrer"
  }

  const embedCode = `<iframe src="${document.location.href}/embed" allowfullscreen sandbox="allow-scripts allow-same-origin allow-downloads allow-forms" width="100%" height="100%" style="display: block; border: 0; height: 100vh;" />`

  return (
    <>
      <div className={stylesheet.container}>
        {gallery.currentUser.canEdit && gallery.privacy === GalleryPrivacy.PRIVATE &&
          <div className={stylesheet.privacyInfo} >
            <img
              src={PrivacyLock} alt={t("altText.icon")}
              onMouseOver={() => setShowPrivacyMessage(true)}
              onMouseOut={() => setShowPrivacyMessage(false)}
            />
            <div className={`${stylesheet.privacyMessage} ${showPrivacyMessage ? stylesheet.showPrivacyMessage : ""}`}>
              {t("gallery.loggedInPrivacyMessage")}
              <div className={stylesheet.triangle}></div>
            </div>
          </div>
        }

        {gallery.currentUser.canEdit && gallery.privacy !== GalleryPrivacy.PRIVATE &&
          <>
            {/* Play Button */}
            <a href={gallery.links.liveFeed} {...linkProps} >
              <img src={playIconSrc} alt={t("altText.icon")} />
              <span>{t("buttons.play")}</span>
            </a>

            {/* Embed Button */}
            <button
              className={`${pillStyle.pillButton} ${pillStyle.mobileCollapse}`}
              onClick={() => {
                setShowEmbedModal(true)
                ReactGA.event({ category: "Gallery Controls", action: "Open Embed Modal" })
              }}
            >
              <img src={EmbedIcon} alt={t("altText.icon")} />
              <span>{t("buttons.embed")}</span>
            </button>
          </>
        }

        {/* Edit Button */}
        {gallery.currentUser.canEdit &&
          <a href={`${API.baseUrl}/galleries?code=${gallery.code}`} {...linkProps} >
            <img src={editIconSrc} alt={t("altText.icon")} />
            <span>{t("buttons.edit")}</span>
          </a>
        }

        {/* Share Button */}
        {gallery.privacy !== GalleryPrivacy.PRIVATE &&
            <button
                className={`${pillStyle.pillButton} ${pillStyle.mobileCollapse}`}
                onClick={() => {
                  setShowShareModal(true)
                  ReactGA.event({category: "Gallery Controls", action: "Share Gallery"})
                }}
            >
              <img src={shareIconSrc} style={{width: 20}} alt={t("altText.icon")}/>
              <span>{t("buttons.share")}</span>
            </button>
        }
      </div>

      <Modal show={showEmbedModal} centered onHide={() => setShowEmbedModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("gallery.promote.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <p>{t("gallery.promote.text")}</p>
          <textarea
            className={textarea.codeBlock}
            onClick={(e) => (e.target as HTMLTextAreaElement).select()}
            readOnly
          >
            {embedCode}
          </textarea>
          <button
            className={pillStyle.pillButton}
            onClick={() => {
              copyText(embedCode, () => setCopied(true))
              ReactGA.event({ category: "Gallery Controls", action: "Copy Gallery Link" })
            }}
          >
            <img src={copied ? checkmarkIconSrc : clipboardIconSrc} alt={t("altText.icon")} />
            {copied ? t("buttons.copied") : t("buttons.copy")}
          </button>
        </Modal.Body>
      </Modal>

      <Modal show={showShareModal} centered onHide={() => setShowShareModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("gallery.share.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <p>{t("gallery.share.text")}</p>
          <textarea
            className={textarea.codeBlock}
            onClick={(e) => (e.target as HTMLTextAreaElement).select()}
            readOnly
          >
            {gallery.links.webSelf}
          </textarea>
          <button
            className={pillStyle.pillButton}
            onClick={() => {
              copyText(gallery.links.webSelf ?? window.location.href, () => setCopied(true))
              ReactGA.event({ category: "Gallery Controls", action: "Copy Embed Code" })
            }}
          >
            <img src={copied ? checkmarkIconSrc : clipboardIconSrc} alt={t("altText.icon")} />
            {copied ? t("buttons.copied") : t("buttons.copy")}
          </button>
        </Modal.Body>
      </Modal>
    </>
  )
}
