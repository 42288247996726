import { useNavigate } from "react-router-dom"
import stylesheet from "./ScrollingCallout.module.scss"
import BlueButton from "../../../Button/BlueButton"
import { Profile } from "@simplebooth/webkit"
import { useTranslation } from "react-i18next"
import ReactGA from "react-ga"

export default function ScrollingCallout({ show, profile }: { show: boolean, profile: Profile }) {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const handleProfileButtonClick = () => {
    ReactGA.event({ category: "Profile Links", action: "Open Profile from Callout", label: profile.username })
    navigate(`/profiles/${profile.username.toLowerCase()}`)
  }

  return (
    <div className={`${stylesheet.callout} ${show ? stylesheet.show : ""}`}>
      <div className={stylesheet.profileSummary} onClick={handleProfileButtonClick}>
        <div className={stylesheet.profileImage}>
          <img src={profile.avatar?.url} alt={t("altText.profileAvatar")} />
        </div>
        <div className={stylesheet.userAttributes}>
          <div className={stylesheet.primaryAttribute}>{profile.displayName ?? profile.company}</div>
          <div className={stylesheet.secondaryAttribute}>@{profile.username}</div>
        </div>
      </div>
      <div className={stylesheet.buttonContainer}>
        <BlueButton compact onClick={handleProfileButtonClick} style={{}}>
          {t("buttons.view")}
        </BlueButton>
      </div>
    </div>
  )
}
