import EmptyState from '../EmptyState/EmptyState'
import {IconWithConfetti} from "@simplebooth/webkit"
import {ReactComponent as Exclamation} from "../../image/exclamation.svg"
import {useTranslation} from 'react-i18next'

export default function GalleryDeletedState() {
  const { t } = useTranslation()

  return (
    <EmptyState fullscreen>
      <IconWithConfetti style={{ height: "340px", width: "100%" }}>
        <Exclamation />
      </IconWithConfetti>
      <h1>{t("gallery.deleted.heading")}</h1>
      <p>{t("gallery.deleted.text")}</p>
    </EmptyState>
  )
}
