import { useState, useEffect } from 'react'
import { Gallery, fetchGallery, Spinner, IconWithConfetti, GallerySortType } from "@simplebooth/webkit"
import { ReactComponent as PrivacyLock } from "../../../image/privacy-lock.svg"
import { ReactComponent as Exclamation } from "../../../image/exclamation.svg"
import Feed from '../../Feed/Feed'
import EmptyState from '../../EmptyState/EmptyState'
import Container, { ContainerSize } from '../../Container/Container'
import { SUPPORT_EMAIL } from '../../../library/globals'
import { useParams } from 'react-router-dom'


export default function GalleryEmbed() {
  const [gallery, setGallery] = useState<Gallery | null>(null)
  const [numberOfColumns, setNumberOfColumns] = useState(1)

  const [errorCode, setErrorCode] = useState<number | null>(null)

  const { friendlyUrl } = useParams()

  useEffect(() => {
    if (!friendlyUrl) return 

    const galleryCode = friendlyUrl.split("-")[0] // Extract code from friendly URL

    fetchGallery(
      galleryCode,
      GallerySortType.NEWEST,
      5,
      null,
      (galleryResource) => {
        setGallery(
          Gallery.fromLegacyResource(galleryResource)
        )
      }, (errorCode) => {
        setErrorCode(errorCode)
      })
  }, [friendlyUrl])

  if (errorCode) {
    switch (errorCode) {
      case 401:
        return (
          <EmptyState fullscreen>
            <IconWithConfetti largeShadow style={{ height: "340px", width: "100%" }}>
              <PrivacyLock />
            </IconWithConfetti>
            <h1>This gallery is private</h1>
            <p>The gallery owner has restricted who can view these photos. <a href="https://help.simplebooth.com/en/articles/822500-what-privacy-options-do-i-have-for-my-galleries" target="_blank" rel="noreferrer">Learn more</a></p>
          </EmptyState>
        )
      case 404:
        return (
          <EmptyState fullscreen>
            <IconWithConfetti style={{ height: "340px", width: "100%" }}>
              <Exclamation />
            </IconWithConfetti>
            <h1>Page not found</h1>
            <p>Sorry, we couldn't find that gallery.</p>
          </EmptyState>
        )
      case 500:
        return (
          <EmptyState fullscreen>
            <IconWithConfetti style={{ height: "340px", width: "100%" }}>
              <Exclamation />
            </IconWithConfetti>
            <h1>Something went wrong 🤔</h1>
            <p>Refresh and try again shortly.</p>
          </EmptyState>
        )
      default:
        return (
          <EmptyState fullscreen>
            <IconWithConfetti style={{ height: "340px", width: "100%" }}>
              <Exclamation />
            </IconWithConfetti>
            <h1>{errorCode} error</h1>
            <p>If the problem persists, contact {SUPPORT_EMAIL}.</p>
          </EmptyState>
        )
    }
  }

  if (!gallery) {
    return <Spinner centerVertical />
  }

  return (
    <Container size={ContainerSize.LARGE}>
      <Feed
        gallery={gallery}
        numberOfColumns={numberOfColumns}
        setNumberOfColumns={setNumberOfColumns}
        hideControls
      />
    </Container>
  )
}
