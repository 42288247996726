
import { ColorUtil, DeviceUtil, Gallery, MediaQuality, MediaView, ShareSheet, trackView, Upload, UploadLayout } from "@simplebooth/webkit"
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import PlayIcon from "../../../image/play-video.svg"
import PrimaryButton from '../../Button/PrimaryButton'
import UploadControls from '../../Feed/UploadCard/UploadControls/UploadControls'
import MediaPlaceholder from '../../MediaPlaceholder/MediaPlaceholder'
import stylesheet from "./PicView.module.scss"

interface PicViewProps {
  upload: Upload
  gallery: Gallery
}

export default function PicView({ upload, gallery }: PicViewProps) {
  const [showShareSheet, setShowShareSheet] = useState(false)
  const [isMediaReady, setIsMediaReady] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const [clickedToPlay, setClickedToPlay] = useState(false)

  const { t } = useTranslation()

  let isLongformVideo = upload.layout === UploadLayout.VIDEO
  let isRebound = upload.layout === UploadLayout.REBOUND
  let sharePrompt = isLongformVideo ? t("pic.sharePrompt.video") : t("pic.sharePrompt.image")
  if (upload.dimensions.aspect < 0.5) sharePrompt = t("pic.sharePrompt.shortGeneric")

  let autoPlayWithoutAudio = upload.layout === UploadLayout.REBOUND || DeviceUtil.isTouchDevice()
  let shareOptions = gallery.getShareOptions()
  let canEdit = upload.links.delete !== undefined

  useEffect(() => {
    if (upload.links.delete) return

    trackView(upload.code)
  }, [upload])

  return (
    <section>
      <div
        className={stylesheet.card}
        style={{ maxWidth: Math.max(upload.dimensions.aspect * 840, 320) + "px" }}
      >
        <MediaPlaceholder
          aspectRatio={upload.dimensions.aspect}
          playAnimation={!isMediaReady}
          onMouseOver={(_) => setShowActions(true)}
          onMouseLeave={(_) => setShowActions(false)}
        >
          <MediaView
            upload={upload}
            quality={isLongformVideo ? MediaQuality.LOW : MediaQuality.HIGH}
            className={`${stylesheet.mediaView} ${isMediaReady ? stylesheet.opaque : stylesheet.transparent}`}
            videoOptions={{
              play: clickedToPlay || autoPlayWithoutAudio,
              aspectFit: true,
              attributes: {
                autoPlay: autoPlayWithoutAudio,
                muted: autoPlayWithoutAudio || !clickedToPlay,
                controls: clickedToPlay || (autoPlayWithoutAudio && isLongformVideo),
                loop: isRebound,
                playsInline: true
              }
            }}
            isReadyCallback={() => { setIsMediaReady(true) }}
          />
          {upload.isVideo() && !clickedToPlay && !autoPlayWithoutAudio &&
            <div className={stylesheet.playIconContainer} onClick={() => setClickedToPlay(true)}>
              <img
                src={PlayIcon}
                className={stylesheet.playIcon}
                alt={t("altText.icon")}
              />
            </div>
          }
          {canEdit &&
            <div style={{ position: "relative", pointerEvents: "none" }} >
              <UploadControls
                upload={upload}
                showButton={showActions}
                editable={canEdit}
                admin={gallery.currentUser.isAdmin}
                handleReport={() => { }}
                onMakePrivate={() => { upload.public = false }}
                onDelete={() => {
                  window.location.reload()
                }}
              />
            </div>
          }
        </MediaPlaceholder>

        {shareOptions.length > 0 &&
          <PrimaryButton
            className={stylesheet.shareButton}
            style={{
              backgroundColor: "#30aade",
              boxShadow: `0px 5px 0px ${ColorUtil.hexToCssRgba("#30aade", 0.7)}`,
            }}
            onClick={() => {
              setShowShareSheet(true)
              ReactGA.event({ category: "Pic View", action: "Open Share Sheet" })
            }}>
            {sharePrompt}
          </PrimaryButton>
        }
      </div>

      <Modal show={showShareSheet} centered onHide={() => setShowShareSheet(false)}>
        <Modal.Body className="p-4">
          <ShareSheet
            heading={sharePrompt}
            upload={upload}
            hashtags={gallery.hashtag}
            shareOptions={gallery.getShareOptions()}
            onHide={() => setShowShareSheet(false)}
          />
        </Modal.Body>
      </Modal>
    </section>
  )
}
