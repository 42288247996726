import { useNavigate, useParams } from "react-router-dom"
import { fetchUploadBySessionId, IconWithConfetti } from '@simplebooth/webkit'
import { useEffect, useState, useRef, useCallback } from 'react'
import { Spinner } from "@simplebooth/webkit"
import { ReactComponent as Rocket } from "../../image/rocket.svg"
import BlueButton from "../Button/BlueButton"
import EmptyState from "../EmptyState/EmptyState"
import InternalServerErrorState from "../ErrorState/InternalServerErrorState"
import QrCanceledErrorState from "../ErrorState/QrCanceledErrorState"
import UploadDeletedState from "../ErrorState/UploadDeletedState"
import DefaultErrorState from "../ErrorState/DefaultErrorState"
import { Trans, useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"

export default function QrLoadingPage() {
  const [numberOfTries, setNumberOfTries] = useState(0)
  const [stopPolling, setStopPolling] = useState(false)
  const [errorCode, setErrorCode] = useState<number | null>(null)

  const numberOfTriesRef = useRef(numberOfTries)
  numberOfTriesRef.current = numberOfTries

  const navigate = useNavigate()
  const { qrCode } = useParams()
  const { t } = useTranslation()

  const triesLimit = 5

  const tryToFetchUpload = useCallback(() => {
    if (!qrCode) return

    fetchUploadBySessionId(
      qrCode,
      (uploadResource) => {
        setStopPolling(true)
        navigate(`/pic/${uploadResource.code}`)
      }, (code) => {
        const retry = () => {
          let newNumberOfTries = numberOfTriesRef.current + 1

          setNumberOfTries(newNumberOfTries)
          if (newNumberOfTries >= triesLimit) {
            setStopPolling(true)
          } else {
            let interval = 3000 * newNumberOfTries
            setTimeout(tryToFetchUpload, interval)
          }
        }

        code === 404 ? retry() : setErrorCode(code)
      })
  }, [qrCode, navigate])

  const startOver = () => {
    setStopPolling(false)
    setNumberOfTries(0)
    tryToFetchUpload()
  }

  useEffect(() => {
    tryToFetchUpload()
  }, [tryToFetchUpload])

  if (errorCode) {
    switch (errorCode) {
      case 410:
        return <UploadDeletedState />
      case 418:
        return <QrCanceledErrorState />
      case 500:
        return <InternalServerErrorState />
      default:
        return <DefaultErrorState errorCode={errorCode} />
    }
  }

  return (
    <>
      <Helmet>
        <title>QR Loading | Simple Booth</title>
      </Helmet>
      <EmptyState fullscreen>
        <IconWithConfetti largeIcon largeShadow style={{ height: "340px", width: "100%" }}>
          <Rocket />
        </IconWithConfetti>

        {stopPolling ? (
          <>
            <h1>{t("qr.stopped.heading")}</h1>
            <p>{t("qr.stopped.text")}</p>
            <p>
              <Trans i18nKey="qr.stopped.secondaryText">
                If the booth is <i>completely</i> offline, it may be a while before the host can connect it to the internet.
              </Trans>
            </p>
            <BlueButton
              style={{ marginBottom: "30px" }}
              onClick={() => startOver()}>
              {t("qr.stopped.retryButton")}
            </BlueButton>
          </>
        ) : (
          <>
            <h1>{t("qr.checking.heading")}</h1>
            <p>
              <Trans i18nKey="qr.checking.text">
                <b>Make sure you pressed "Done"</b> on-site. Your photo will appear after it uploads, which varies depending on the internet connection.
              </Trans>
            </p>
            <Spinner style={{ margin: "30px 0" }} />
          </>
        )}
      </EmptyState>
    </>
  )
}
