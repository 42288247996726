/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from 'react'
import { Gallery, GallerySortType, ShareSheet, Upload, UploadLayout } from "@simplebooth/webkit"
import UploadCard, { SizeContext } from "../UploadCard/UploadCard"
import AdCard from "../AdCard/AdCard"
import ImageAdCard from "../ImageAdCard/ImageAdCard"
import Ad from "../../../library/Ad"
import { FeedItem } from '../../../library/FeedItem'
import ImageAd from "../../../library/ImageAd"
import stylesheet from "./CardController.module.scss"
import FullscreenViewer from '../FullscreenViewer/FullscreenViewer'
import { Modal } from 'react-bootstrap'
import ReportModal from '../UploadCard/ReportModal/ReportModal'
import { useTranslation } from 'react-i18next'

interface CardControllerProps {
  gallery: Gallery
  items: FeedItem[] | null
  numberOfColumns: number
  sorting: GallerySortType
  handleRemoveUpload: (upload: Upload) => void
}

export default function CardController({ gallery, items, numberOfColumns, sorting, handleRemoveUpload }: CardControllerProps) {
  const [itemsByColumn, setItemsByColumn] = useState<FeedItem[][]>([])
  const [showFullscreenUpload, setShowFullscreenUpload] = useState(false)
  const [fullscreenUpload, setFullscreenUpload] = useState<Upload | null>(null)

  const [showShareSheet, setShowShareSheet] = useState(false)
  const [sharingUpload, setSharingUpload] = useState<Upload | null>(null)

  const [showReportModal, setShowReportModal] = useState(false)
  const [reportUpload, setReportUpload] = useState<Upload | null>(null)

  const [indexesInView, setIndexesInView] = useState<number[]>([])
  const [homeUrl, setHomeUrl] = useState<string>(window.location.href)

  const { t } = useTranslation()

  useEffect(() => {
    // Update page path when viewing uploads fullscreen or exiting viewer
    let url = homeUrl
    if (fullscreenUpload && showFullscreenUpload) {
      url = `${window.location.origin}/pic/${fullscreenUpload.code}`
    }
    window.history.replaceState({}, "", url)
  }, [showFullscreenUpload, fullscreenUpload])

  useEffect(() => setHomeUrl(window.location.href), [sorting])

  useEffect(() => {
    if (!items) return

    let columns: FeedItem[][] = []

    for (let i = 0; i < items.length; i++) {
      let columnNumber = i % numberOfColumns
      if (columns.length <= columnNumber) {
        columns.push([])
      }
      columns[columnNumber].push(items[i])
    }

    setItemsByColumn(columns)
  }, [items, numberOfColumns, setItemsByColumn])

  const isItemAtIndexNearViewPort = (index: number) => {
    let min = Math.min(...indexesInView)
    let max = Math.max(...indexesInView)

    let distance = numberOfColumns * 2
    return index >= (min - distance) && index <= (max + distance)
  }

  return (
    <>
      <div className={stylesheet.columnsContainer} style={{ gridTemplateColumns: `repeat(${numberOfColumns}, minmax(0, 1fr))` }}>
        {itemsByColumn.map((column, columnNumber) => {
          return <div key={columnNumber}>
            {column.map((item, columnIndex) => {
              let itemIndex = (columnIndex * numberOfColumns) + columnNumber
              if (item instanceof Upload) {
                return (
                  <UploadCard
                    upload={item}
                    context={numberOfColumns === 1 ? SizeContext.SINGLE_COLUMN : SizeContext.MULTICOLUMN}
                    sortedBy={sorting}
                    editable={gallery.currentUser.canEdit}
                    admin={gallery.currentUser.isAdmin}
                    requestFullscreen={(upload) => {
                      setFullscreenUpload(upload)
                      setShowFullscreenUpload(true)
                    }}
                    handleShare={(upload) => {
                      setSharingUpload(upload)
                      setShowShareSheet(true)
                    }}
                    handleReport={(upload) => {
                      setReportUpload(upload)
                      setShowReportModal(true)
                    }}
                    handleRemoveCard={handleRemoveUpload}
                    handleInView={(inView) => {
                      // Add or remove the index from the array
                      let mutableArray = [...indexesInView]
                      if (inView) {
                        mutableArray.push(itemIndex)
                      } else {
                        mutableArray = mutableArray.filter(anIndex => anIndex !== itemIndex)
                      }
                      setIndexesInView(mutableArray)
                    }}
                    nearViewport={isItemAtIndexNearViewPort(itemIndex)}
                    key={`${columnNumber}-${columnIndex}-${item.code}`}
                  />
                )
              }
              if (item instanceof Ad) {
                const ad = item as Ad
                return <AdCard ad={ad} small={numberOfColumns > 1} key={itemIndex} />
              }
              if (item instanceof ImageAd) {
                const imageAd = item as ImageAd
                return <ImageAdCard src={imageAd.imageSrc} href={imageAd.href} key={itemIndex} />
              }
              return <></>
            })}
          </div>
        })}
      </div>

      {fullscreenUpload &&
        <FullscreenViewer
          key={fullscreenUpload.code}
          show={showFullscreenUpload}
          upload={fullscreenUpload}
          all={items?.filter(item => { return item instanceof Upload }) as Upload[]}
          trackViews={!gallery.currentUser.canEdit}
          changeUpload={setFullscreenUpload}
          onClose={() => setShowFullscreenUpload(false)}
        />
      }

      {sharingUpload &&
        <Modal show={showShareSheet} centered onHide={() => setShowShareSheet(false)}>
          <Modal.Body className="p-4">
            <ShareSheet
              oneColumn
              heading={sharingUpload.layout === UploadLayout.VIDEO ? t("shareSheet.headings.video") : t("shareSheet.headings.photo")}
              upload={sharingUpload}
              hashtags={gallery.hashtag}
              shareOptions={gallery.getShareOptions()}
              showNewWindowButton
              onHide={() => setShowShareSheet(false)}
            />
          </Modal.Body>
        </Modal>
      }

      {reportUpload &&
        <ReportModal
          key={reportUpload.code}
          show={showReportModal}
          upload={reportUpload}
          onHide={() => setShowReportModal(false)}
        />
      }
    </>
  )
}
