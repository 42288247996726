/* eslint-disable react/jsx-no-target-blank */
import { API, Profile } from "@simplebooth/webkit"
import OpenLinkExternal from "../../../image/new-window.svg"

interface ProfileBadgeProps {
  profile: Profile
}

export default function ProfileBadge({ profile }: ProfileBadgeProps) {
  return (
    <>
      <style>
        {`
        #sbBadge {
          all: unset;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          font-family: Gotham, Helvetica, Arial, sans-serif;
          justify-content: space-between;
          padding: 18px;
          height: 100px;
          width: 325px;
          border: 3px solid #c9c9c9;
          border-radius: 6px;
          background-color: white;

          transition: transform 0.2s ease-in-out;
        }
        #sbBadge:hover {
          color: #252525;
          transform: scale(1.05);
        }
        #sbBadge:hover > #sbOpenWindowIcon{
          background-color: #f0f0f0;
        }
        #sbBadge div > div:first-child {
          margin-top: 9px;
          font-size: 20px;
          line-height: 20px;
          font-weight: 600;
          color: #2d2d2d;
        }
        #sbBadge div > div:last-child {
          font-size: 16px;
          font-weight: 600;
          color: #999;
        }
        #sbAvatar {
          width: 64px;
          height: 64px;
          border: 2px solid #dfdfdf;
        }
        #sbOpenWindowIcon {
          width: 36px;
          height: 36px;
          padding: 6px;
          border-radius: 50%;
          opacity: 0.5;
          align-self: center;
        }
        `}
      </style>
      <a
        id="sbBadge"
        href={`${API.baseUrl}/profiles/${profile.username}`}
        target="_blank"
      >
        <img id={"sbAvatar"} src={profile.avatar?.url} alt="Avatar" style={{ borderRadius: "30px" }} />
        <div>
          <div>View Galleries</div>
          <div>@{profile.username}</div>
        </div>
        <img id={"sbOpenWindowIcon"} src={"https:" + OpenLinkExternal} alt="New window icon" />
      </a>
    </>
  )
}
