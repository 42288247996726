import { Gallery } from "@simplebooth/webkit";
import { useState } from "react";
import Toast, { ToastType } from "../Toast/Toast";

export default function FinePrintDisplayer({ gallery }: { gallery: Gallery }) {
  const [hideFinePrint, setHideFinePrint] = useState(false)

  if (!gallery.finePrint) {
    return <></>
  }
  
  return (
    <Toast type={ToastType.INFO} show={!hideFinePrint} onClose={() => setHideFinePrint(true)}>
      <span dangerouslySetInnerHTML={{ __html: gallery.finePrint.replace("<p>", "").replace("</p>", "") }} />
    </Toast>
  )
}