import { ReactNode } from "react"
import stylesheet from "./Toast.module.scss"

interface ToastProps {
  show: boolean
  type: ToastType
  children: ReactNode
  onClose?: () => void
}

export enum ToastType {
  ERROR, INFO
}

export default function Toast({ show, type, children, onClose }: ToastProps) {
  return (
    <div className={`${stylesheet.toast} ${type === ToastType.ERROR ? stylesheet.error : stylesheet.info} ${show ? stylesheet.show : stylesheet.hide}`}>
      <span>{children}</span>
      <button onClick={onClose}></button>
    </div>
  )
}