import { DeviceUtil } from "@simplebooth/webkit";
import { ReactNode, useEffect, useMemo, useState } from "react"
import stylesheet from "./BrandedBackground.module.scss"
import ConfettiField from "./ConfettiField/ConfettiField"
import throttle from 'lodash.throttle'

interface BrandedBackgroundProps {
  fixed?: boolean
  firstColor?: string
  secondColor?: string
  backgroundImageSrc?: string
  allowDefaultBackgroundConfetti?: boolean
  children?: ReactNode
}

export default function BrandedBackground({ children, firstColor, secondColor, fixed, backgroundImageSrc, allowDefaultBackgroundConfetti }: BrandedBackgroundProps) {
  const [backgroundStyle, setBackgroundStyle] = useState<string>(`radial-gradient(#ffffff, #dddddd)`)
  const [playConfetti, setPlayConfetti] = useState<boolean>(true)

  const handleScroll = () => {
    setPlayConfetti(!DeviceUtil.isTouchDevice() || window.scrollY < 1000)
  }

  const throttledHandleScroll = useMemo(
    () => throttle(handleScroll, 300), []
  )

  useEffect(() => {
    window.addEventListener("scroll", throttledHandleScroll)
    return () => window.removeEventListener("scroll", throttledHandleScroll)
  }, [throttledHandleScroll])

  useEffect(() => {
    if (firstColor && secondColor) {
      setBackgroundStyle(`radial-gradient(${firstColor}, ${secondColor})`)
    } else if (firstColor) {
      setBackgroundStyle(firstColor)
    }
  }, [firstColor, secondColor])

  if (fixed) {
    const bgStyle = backgroundImageSrc
      ? { background: `url(${backgroundImageSrc}) center center / cover no-repeat, ${backgroundStyle}` }
      : { background: backgroundStyle }

    return (
      <>
        <div
          className={stylesheet.staticBackground}
          style={bgStyle}>
          {allowDefaultBackgroundConfetti && !backgroundImageSrc && <ConfettiField play={playConfetti} />}
        </div>
        <div>
          {children}
        </div>
      </>
    )
  }

  return (
    <>
      <div
        className={stylesheet.background}
        style={{ background: backgroundStyle }}>
      </div>
      <div className={stylesheet.foreground}>
        {children}
      </div>
    </>
  )
}