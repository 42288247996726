import { IconWithConfetti } from "@simplebooth/webkit"
import EmptyState from "../EmptyState"
import { ReactComponent as Exclamation } from "../../../image/exclamation.svg"
import { useTranslation } from 'react-i18next'

export default function PageNotFound() {
  const { t } = useTranslation()

  return (
    <EmptyState fullscreen>
      <IconWithConfetti style={{ height: "340px", width: "100%" }}>
        <Exclamation />
      </IconWithConfetti>
      <h1>{t("errorState.pageNotFound")}</h1>
    </EmptyState>
  )
}