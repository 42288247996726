import { ReactNode } from "react"
import { CSSProperties } from "react"
import buttonStyles from "./Button.module.scss"

interface PrimaryButtonProps {
  style?: CSSProperties
  className?: string
  children?: ReactNode
  onClick?: () => void
}

export default function PrimaryButton({ children, style, className, onClick }: PrimaryButtonProps) {
  return (
    <button className={`${buttonStyles.primary} ${className ?? ""}`} style={style} onClick={onClick}>
      {children}
    </button>
  )
}
