
import Ad from "../../library/Ad"
import ImageAd from '../../library/ImageAd'
import { FeedItem } from '../../library/FeedItem'
import { Gallery, Upload } from "@simplebooth/webkit"
import Ad1 from "../../image/ads/1.jpg"
import Ad2 from "../../image/ads/2.jpg"
import Ad3 from "../../image/ads/3.jpg"
import Ad4 from "../../image/ads/4.jpg"
import Ad5 from "../../image/ads/5.jpg"

export const insertAds = (ads: (Ad | ImageAd)[], items: FeedItem[], frequency: number) => {
  let lastInsertedAdIndex = 0

  if (ads && ads.length === 0) {
    return
  }

  // Count backward to find last ad index
  for (let i = items.length - 1; i >= 0; i--) {
    if (items[i] instanceof Ad || items[i] instanceof ImageAd) {
      lastInsertedAdIndex = i
      break
    }
  }

  let adCount = items.filter(item => item instanceof Ad || item instanceof ImageAd).length

  // Insert new ads after the last inserted ad index
  for (let i = lastInsertedAdIndex + frequency; i < items.length; i += frequency) {
    items.splice(i, 0, selectNextAdFromSet(ads, adCount))
    adCount++
  }
}

export const removeAds = (items: FeedItem[]) => {
  return items.filter(item => {
    return !(item instanceof Ad) && !(item instanceof ImageAd)
  })
}

export const resolveAdFrequency = (numberOfColumns: number) => {
  switch (numberOfColumns) {
    case 1:
      return 5
    case 2:
      return 9
    case 3:
      return 11
    default:
      return 13
  }
}

export const resolveCustomAd = (gallery: Gallery): Ad | ImageAd => {
  if (gallery.design.bottomImageAsset) {
    return new ImageAd(gallery.design.bottomImageAsset.url, gallery.design.bottomUrl)
  }

  return new Ad()
}

export const resolveDefaultAdSet = (): (Ad | ImageAd)[] => {
  let adHref = "https://www.simplebooth.com"

  return [
    new ImageAd(Ad1, adHref),
    new ImageAd(Ad2, adHref),
    new ImageAd(Ad3, adHref),
    new ImageAd(Ad4, adHref),
    new ImageAd(Ad5, adHref)
  ]
}

const selectNextAdFromSet = (ads: (Ad | ImageAd)[], adCount: number): Ad | ImageAd => {
  return ads[adCount % ads.length]
}

export const removeUpload = (uploadCode: string, items: FeedItem[]): FeedItem[] => {
  let filteredItems = [...items].filter(item => {
    if (item instanceof Upload) {
      return item.code !== uploadCode
    }
    return true
  })

  return filteredItems
}