/* eslint-disable react-hooks/exhaustive-deps */
import { MouseEventHandler, useEffect, useRef, useState } from 'react'
import { DeviceUtil, MediaQuality, MediaView, Spinner, trackView, Upload, UploadLayout, AspectFit } from "@simplebooth/webkit"
import CloseIcon from "../../../image/close-white.svg"
import stylesheet from "./FullscreenViewer.module.scss"

interface FullscreenViewerProps {
  show: boolean
  upload: Upload
  all: Upload[]
  trackViews: boolean
  changeUpload: (upload: Upload) => void
  onClose: () => void
}

export default function FullscreenViewer({ show, upload, all, trackViews, changeUpload, onClose }: FullscreenViewerProps) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [viewTracked, setViewTracked] = useState(false)

  const handleKeypress = (event: KeyboardEvent) => {
    if (!show) return

    let codes = all.map(upload => upload.code)
    let index = codes.indexOf(upload.code)

    switch (event.key) {
      case "Escape":
        onClose()
        break;
      case "ArrowRight":
        index = index >= codes.length - 1 ? 0 : index + 1
        changeUpload(all[index])
        break;
      case "ArrowLeft":
        index = index <= 0 ? codes.length - 1 : index - 1
        changeUpload(all[index])
        break;
      default:
        break;
    }
  }

  const timerRef = useRef<NodeJS.Timeout>()
  const startViewTimer = () => {
    cancelViewTimer()
    timerRef.current = setTimeout(() => {
      if (viewTracked) return
      if (!trackViews) return

      setViewTracked(true)
      trackView(upload.code)
    }, 2000)
  }
  const cancelViewTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current)
  }

  useEffect(() => {
    show ? startViewTimer() : cancelViewTimer()
  }, [show])

  useEffect(() => {
    setViewTracked(false)
    startViewTimer()
    return cancelViewTimer
  }, [upload])

  useEffect(() => {
    document.addEventListener("keydown", handleKeypress, false);
    return () => { document.removeEventListener("keydown", handleKeypress, false); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick: MouseEventHandler = (e) => {
    let target = e.target as HTMLElement
    if (target.className.includes(stylesheet.fullscreen)) onClose()
  }

  const isLongformVideo = upload.layout === UploadLayout.VIDEO

  return (
    <>
      <div className={`${stylesheet.fullscreen} ${show ? stylesheet.show : ""}`} onClick={handleClick}>
        {!isLoaded &&
          <Spinner color="#ffffff" centerVertical />
        }
        <AspectFit aspectRatio={upload.dimensions.aspect} className={`${stylesheet.mediaView} ${isLoaded ? stylesheet.loaded : ""}`}>
          <MediaView
            upload={upload}
            quality={MediaQuality.HIGH}
            videoOptions={{
              play: show,
              aspectFit: true,
              previewOnly: false,
              attributes: {
                autoPlay: true,
                muted: !upload.hasAudio() || DeviceUtil.isTouchDevice(),
                controls: isLongformVideo,
                loop: !isLongformVideo,
                playsInline: true
              }
            }}
            isReadyCallback={() => setIsLoaded(true)}
          />
        </AspectFit>
        <img src={CloseIcon} className={stylesheet.closeButton} alt="Close button" onClick={onClose} />
      </div>
      {show && <style>{`
      body {
        overflow: hidden !important;
      }
    `}</style>}
    </>
  )
}
