import { ImageAsset, Profile } from "@simplebooth/webkit"
import DefaultLogo from "../image/simple-booth-logo-white.svg"
import i18next from "../i18n"
import { CSSProperties } from "react"

export default class Ad {
  text: string
  backgroundColor: string
  textColor: string
  callToAction?: {
    title: string
    href: string
    backgroundColor: string
    textColor: string
  }
  headerImage: ImageAsset | null
  headerImageStyles: CSSProperties | null

  constructor() {
    this.text = "Get an awesome digital photo booth for your business or event."
    this.backgroundColor = "#ffffff"
    this.textColor = "#333333"
    this.callToAction = {
      title: "Learn More",
      href: "https://www.simplebooth.com",
      backgroundColor: "#ffffff",
      textColor: "#30aade"
    }
    this.headerImage = {
      url: DefaultLogo,
      mimeType: "image/svg",
      hash: ""
    }
    this.headerImageStyles = {
      maxWidth: "25%"
    }
  }

  static fromProfile(profile: Profile) {
    let ad = new Ad()

    const entity = (profile.company || profile.industry) ? profile.displayName : `@${profile.username}`
    ad.text = i18next.t("feed.cards.profile.text", { name: entity })

    ad.headerImage = profile.avatar
    ad.headerImageStyles = {
      borderRadius: "50%",
      maxWidth: "20%"
    }

    ad.callToAction = {
      title: i18next.t("feed.cards.profile.buttonLabel"),
      href: `/profiles/${profile.username}`,
      backgroundColor: '#30aade',
      textColor: '#ffffff'
    }
    return ad
  }
}
