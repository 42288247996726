import { ReactNode } from "react"
import { CSSProperties } from "react"
import Container, { ContainerSize } from "../Container/Container"
import stylesheet from "./EmptyState.module.scss"

interface EmptyStateProps {
  style?: CSSProperties
  className?: string
  fullscreen?: boolean
  asCard?: boolean
  naked?: boolean
  children?: ReactNode
}

export default function EmptyState({ children, style, className, asCard, naked, fullscreen }: EmptyStateProps) {
  let emptyState = (
    <div className={`${stylesheet.emptyState} ${className} ${asCard ? stylesheet.card : ""} ${fullscreen ? stylesheet.fullscreen : ""}`} style={style}>
      <div style={{ width: "100%" }}>
        {children}
      </div>
    </div>
  )

  if (naked) {
    return emptyState
  }

  return (
    <Container size={ContainerSize.SMALL}>
      {emptyState}
    </Container>
  )
}
