import { ReactNode } from "react"
import styles from "./Container.module.scss"
import animations from "../../styles/Animations.module.scss"
import { CSSProperties } from "react"

export enum ContainerSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large"
}

interface ContainerProps {
  children: ReactNode
  className?: string
  style?: CSSProperties
  size?: ContainerSize
}

export default function Container({ children, className, style, size }: ContainerProps) {

  let sizeClass: string
  switch (size) {
    case ContainerSize.LARGE:
      sizeClass = styles.large
      break;
    case ContainerSize.MEDIUM:
      sizeClass = styles.medium
      break;
    default:
      sizeClass = styles.small
      break;
  }

  return (
    <div
      className={`${styles.container} ${sizeClass} ${animations.fadeIn} ${className && className}`}
      style={style}
    >
      {children}
    </div>
  )
}