import stylesheet from "./ConfettiPiece.module.scss"

import { ReactComponent as Squiggle } from "../../../image/confetti/squiggle.svg"
import { ReactComponent as Square } from "../../../image/confetti/square.svg"
import { ReactComponent as Circle } from "../../../image/confetti/circle.svg"
import { CSSProperties } from "react"

interface ConfettiPieceProps {
  className?: string
  type: ConfettiType
  size?: ConfettiSize
  color: ConfettiColor
  opacity?: number
  position?: { left: string, top: string }
  reverseAnimation?: boolean
  play: boolean
}

export enum ConfettiType {
  SQUIGGLE, CIRCLE, SQUARE
}

export enum ConfettiSize {
  EXTRA_SMALL,
  SMALL,
  MEDIUM,
  LARGE
}

export enum ConfettiColor {
  BLUE = "#30AADE",
  GREEN = "#58C1BD",
  ORANGE = "#FF8155",
  PURPLE = "#8652D0",
  YELLOW = "#FFDD6E"
}

export default function ConfettiPiece({ className, type, color, size, opacity, position, reverseAnimation, play }: ConfettiPieceProps) {

  const dimensionsForSize = (size: ConfettiSize) => {
    let length: number = 60
    if (size === ConfettiSize.EXTRA_SMALL)
      length = 16
    else if (size === ConfettiSize.SMALL)
      length = 30
    else if (size === ConfettiSize.MEDIUM)
      length = 45
    else if (size === ConfettiSize.LARGE)
      length = 60
    return {
      width: length + "px", 
      height: length + "px"
    }
  }

  let style: CSSProperties = { 
    opacity: opacity ?? 0.5,
    animationDelay: Math.random() * 2 + "s",
    animationDuration: (Math.random() * 18) + 22 + "s",
    animationPlayState: play ? "running" : "paused",
    ...position
  }

  if (position) style.position = "absolute"

  let props: React.SVGProps<SVGSVGElement> = {
    className: `${className ?? ""} ${reverseAnimation ? stylesheet.rotateReverse : stylesheet.rotate}`,
    fill: color,
    style: style,
    ...dimensionsForSize(size ?? ConfettiSize.LARGE)
  }

  switch (type) {
    case ConfettiType.SQUIGGLE:
      return <Squiggle {...props} />

    case ConfettiType.SQUARE:
      return <Square {...props} />

    case ConfettiType.CIRCLE:
    default:
      return <Circle {...props} />
  }
}