import { Upload } from "@simplebooth/webkit"
import { Form, Modal } from "react-bootstrap"
import BlueButton from "../../../Button/BlueButton"

interface ReportModalProps {
  show: boolean
  upload: Upload
  onHide: () => void
}

export default function ReportModal({ show, upload, onHide }: ReportModalProps) {
  return (
    <Modal
      show={show}
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Report Photo</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form.Group controlId="privacyLevel">
          <Form.Label>Reason for reporting</Form.Label>
          <Form.Control as="select">
            <option disabled selected value={""}>Select an option</option>
            <option value="privacy">Privacy concern</option>
            <option value="nudity">Nudity</option>
            <option value="hate">Hate speech</option>
            <option value="violent">Violence</option>
            <option value="illegal">Illegal</option>
            <option value="inappropriate">Inappropriate</option>
            <option value="offensive">Offensive</option>
            <option value="other">Other</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="ownership">
          <Form.Label>Is this your photo?</Form.Label>

          <Form.Control as="select">
            <option disabled selected value={""}>Select an option</option>
            <option value="no">No</option>
            <option value="yes">Yes</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="comments" className="mb-3">
          <Form.Label>Comments</Form.Label>
          <Form.Control as="textarea" rows={2} />
        </Form.Group>

        {/* <Form.Group controlId="email">
          <Form.Label>Provide your email address if you'd like a follow-up</Form.Label>
          <Form.Control type="email" placeholder="Enter email" />
  </Form.Group> */}
      </Modal.Body>
      <Modal.Footer>
        <BlueButton compact>Submit</BlueButton>
      </Modal.Footer>
    </Modal>
  )
}
