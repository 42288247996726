import EmptyState from '../EmptyState/EmptyState'
import { IconWithConfetti } from "@simplebooth/webkit"
import { ReactComponent as Exclamation } from "../../image/exclamation.svg"
import { useTranslation } from 'react-i18next'
import { PLATFORM_HOME_URL } from '../../library/globals'
import pillButtonStylesheet from "../Button/PillButton.module.scss"

export default function QrCanceledErrorState() {
  const { t } = useTranslation()

  return (
    <EmptyState fullscreen>
      <IconWithConfetti style={{ height: "340px", width: "100%" }}>
        <Exclamation />
      </IconWithConfetti>
      <h1>{t("qr.canceled.heading")}</h1>
      <p>{t("qr.canceled.text")}</p>
      <a className={pillButtonStylesheet.bluePillButton} href={PLATFORM_HOME_URL}>{t("buttons.goHome")}</a>
    </EmptyState>
  )
}
