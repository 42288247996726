import { ReactNode, useEffect, useState } from "react"
import styles from "./MediaPlaceholder.module.scss"
import animations from "../../styles/Animations.module.scss"

interface MediaPlaceholderProps {
  children: ReactNode
  className?: string
  aspectRatio: number
  playAnimation: boolean
  maxWidth?: string
  onMouseOver?: React.MouseEventHandler<HTMLDivElement>
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>
}

export default function MediaPlaceholder({ children, className, aspectRatio, playAnimation, maxWidth, ...props }: MediaPlaceholderProps) {
  const [animating, setAnimating] = useState(playAnimation)

  useEffect(() => {
    if (!playAnimation) {
      // Wait 500 ms to stop animation to allow for transitions
      setTimeout(() => setAnimating(false), 500)
    } else {
      setAnimating(true)
    }
  }, [playAnimation])

  return (
    <div
      className={className}
      style={{
        maxWidth: maxWidth ? maxWidth : "100%",
      }}
      onMouseOver={props.onMouseOver}
      onMouseLeave={props.onMouseLeave}
    >
      <div
        className={`${styles.mediaPlaceholder} ${animations.placeholderShimmer}`}
        style={{
          paddingBottom: 1 / aspectRatio * 100 + "%",
          animationPlayState: animating ? "running" : "paused"
        }}
      >
        {children}
      </div>
    </div >
  )
}
