import { useTranslation } from "react-i18next"
import styles from "./ImageAdCard.module.scss"

interface ImageAdCardProps {
  src: string
  href?: string | null
}

export default function ImageAdCard({ src, href }: ImageAdCardProps) {
  const { t } = useTranslation()

  const img = <img src={src} loading="eager" alt={t("altText.ad")} />
  
  return (
    <div className={styles.card}>
      {href ? (
        <a href={href} target="_blank" rel="noreferrer" >
          {img}
        </a>
      ) : img
      }
    </div>
  )
}
